// Dependencies
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link } from "react-router-dom";
// Local files
import { handleLinkKeyDown } from '../helpers';

// Components
import CookiesBanner from "../Components/CookiesBanner";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";

class NoMatch extends Component {
	render() {
		return (
			<div className="page--body">
				<CookiesBanner />
				<ScrollToTopOnMount />

				<section aria-label="Page not found (404)." className='no-match'>
					<h1><span className="font--bold">404</span> Error.</h1>
					<div className="no-match__divider"></div>
					<p>Oops! It looks like this page was move, removed, or doesn't exist. Try back to our <Link aria-label="Home. Internal link to home page." onKeyDown={handleLinkKeyDown} to="/">Home</Link> page.</p>
				</section>

				<Helmet title='404' />
			</div>
		);
	}
}

export default NoMatch;
