import React, { Component } from 'react';
// import ScrollToTop from 'react-scroll-up';
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// Local files
import { handleLinkKeyDown } from '../helpers';

// Images
import iconBackToTopBlack from '../Images/components/footer-icon-back-to-top-black.png';

import logoCertifications from "../Images/components/footer-logo-certifications.png";
import logoGreatPlacesToWork from '../Images/components/footer-great-places-to-work-logo.png'

import iconSocialFacebook from "../Images/components/footer-icon-social-facebook.png";
import iconSocialFacebookColor from "../Images/components/footer-icon-social-facebook-color.png";
import iconSocialInstagram from "../Images/components/footer-icon-social-instagram.png";
import iconSocialInstagramColor from "../Images/components/footer-icon-social-instagram-color.png";
import iconSocialLinkedin from "../Images/components/footer-icon-social-linkedin.png";
import iconSocialLinkedinColor from "../Images/components/footer-icon-social-linkedin-color.png";
import iconSocialTwitter from "../Images/components/footer-icon-social-twitter.png";
import iconSocialTwitterColor from "../Images/components/footer-icon-social-twitter-color.png";

class Footer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			facebookIcon: 'white',
			instagramIcon: 'white',
			twitterIcon: 'white',
			linkedinIcon: 'white',
			backToTop: 'black'
		};
	}

	componentDidMount() {
		const hideAtPosY = 100;
		const scrollToTopElement = document.querySelector('.footer__scroll');

		window.addEventListener('scroll', () => {

			const footerStyle = document.querySelector('.footer').getBoundingClientRect();

			if ((document.body.scrollTop > hideAtPosY || document.documentElement.scrollTop > hideAtPosY) && (footerStyle.y) >= window.innerHeight) {
				scrollToTopElement.style.opacity = 1;
				scrollToTopElement.style.visibility = 'visible';
			} else {
				scrollToTopElement.style.opacity = 0;
				scrollToTopElement.style.visibility = 'hidden';
			}

		});

		window.addEventListener('resize', this.resizeBodyToFooterHeight, false);
		this.resizeBodyToFooterHeight();
	}

	resizeBodyToFooterHeight() {
		const footerHeight = document.querySelector('footer').getBoundingClientRect().height;
		document.querySelector('body').style.padding = `0 0 ${footerHeight}px 0`;
	}

	scrollToTheTop() {
		const scrollToTop = () => {
			const c = document.documentElement.scrollTop || document.body.scrollTop;
			if (c > 0) {
				window.requestAnimationFrame(scrollToTop);
				window.scrollTo(0, c - c / 5); // default: 8, lower number = faster scroll upwards
			}
		};
		scrollToTop();
	}

	skipToContent = (e) => {
		document.querySelector('.nav-skip').focus();
	};

	printCurrentYear = () => {
		const date = new Date().getFullYear();
		return date;
	};

	render() {
		return (
			<footer aria-label="Footer content." className="footer">
				<div className="footer__inner">
					<div className="footer__inner__left">
						<div className="footer__inner__left__left">
							<div className="footer__inner__left__left__links" role="list">
								<Link aria-label="Solutions. Internal link to solutions page." onKeyDown={handleLinkKeyDown} role="listitem" to="/Solutions">Solutions</Link>
								<Link aria-label="Products. Internal link to products page." onKeyDown={handleLinkKeyDown} role="listitem" to="/Products">Products</Link>
								<Link aria-label="Contact. Internal link to contact page." onKeyDown={handleLinkKeyDown} role="listitem" to="/Contact">Contact</Link>
								<a aria-label="Blog. External link to Curate's blog." href="https://insights.curatemobile.com" role="listitem">Blog</a>
								<Link aria-label="About Us. Internal link to company page." onKeyDown={handleLinkKeyDown} role="listitem" to="/Company">About Us</Link>
								<Link aria-label="Privacy Policy. Internal link to privacy policy page." onKeyDown={handleLinkKeyDown} role="listitem" to="/PrivacyPolicy">Privacy Policy</Link>
							</div>
							<div className="footer__inner__left__left__certifications" role="list">
								<a aria-label="Publisher Login. External link to Curate's publisher platform. Selecting this will open a new window." className="footer__inner__left__left__certifications__button" href="https://console.curatemobile.com/publisher/login" rel="noopener noreferrer" role="listitem" target="_blank">Publisher Login</a>
								<img alt="Group of four certification logos. The items are official member CAAF - Coalition against ad fraud, GDPR compliance, IAB Canada membership, and Level Double-A conformance, W3C WAI Web Content Accessibility Guidelines 2.1" role="listitem" src={logoCertifications} />
							</div>
						</div>
						<div className="footer__inner__left__center">
							<img alt="Great place to work badge. Certified Cananda." src={logoGreatPlacesToWork} />
						</div>
					</div>
					<div className="footer__inner__right">
						<div className="footer__inner__right__social" role="list">
							<a aria-label="Facebook icon. External link to Curate's Facebook page. Selecting this will open a new window." href="https://www.facebook.com/curatemobile/" onBlur={() => { this.setState({ facebookIcon: 'white' }) }} onFocus={() => { this.setState({ facebookIcon: 'colour' }) }} rel="noopener noreferrer" role="listitem" target="_blank">
								<img src={this.state.facebookIcon === 'white' ? iconSocialFacebook : iconSocialFacebookColor} alt="Facebook Icon." onMouseEnter={() => { this.setState({ facebookIcon: 'colour' }) }} onMouseLeave={() => { this.setState({ facebookIcon: 'white' }) }} />
							</a>
							<a aria-label="Instagram icon. External link to Curate's Instagram page. Selecting this will open a new window." href="https://www.instagram.com/curatemobile/" onBlur={() => { this.setState({ instagramIcon: 'white' }) }} onFocus={() => { this.setState({ instagramIcon: 'colour' }) }} rel="noopener noreferrer" role="listitem" target="_blank">
								<img src={this.state.instagramIcon === 'white' ? iconSocialInstagram : iconSocialInstagramColor} alt="Instagram Icon." onMouseEnter={() => { this.setState({ instagramIcon: 'colour' }) }} onMouseLeave={() => { this.setState({ instagramIcon: 'white' }) }} />
							</a>
							<a aria-label="Twitter icon. External link to Curate's Twitter page. Selecting this will open a new window." href="https://twitter.com/curatemobileltd" onBlur={() => { this.setState({ twitterIcon: 'white' }) }} onFocus={() => { this.setState({ twitterIcon: 'colour' }) }} rel="noopener noreferrer" role="listitem" target="_blank">
								<img src={this.state.twitterIcon === 'white' ? iconSocialTwitter : iconSocialTwitterColor} alt="Twitter Icon." onMouseEnter={() => { this.setState({ twitterIcon: 'colour' }) }} onMouseLeave={() => { this.setState({ twitterIcon: 'white' }) }} />
							</a>
							<a aria-label="LinkedIn icon. External link to Curate's LinkedIn page. Selecting this will open a new window." href="https://www.linkedin.com/company/curate-mobile-ltd/" onBlur={() => { this.setState({ linkedinIcon: 'white' }) }} onFocus={() => { this.setState({ linkedinIcon: 'colour' }) }} rel="noopener noreferrer" role="listitem" target="_blank">
								<img src={this.state.linkedinIcon === 'white' ? iconSocialLinkedin : iconSocialLinkedinColor} alt="LinkedIn Icon." onMouseEnter={() => { this.setState({ linkedinIcon: 'colour' }) }} onMouseLeave={() => { this.setState({ linkedinIcon: 'white' }) }} />
							</a>
						</div>
						<div className="footer__inner__right__details">
							<p><span className="font--bold">Inquiries</span> info@curatemobile.com</p>
							<p><span className="font--bold">Phone</span> 1 416 840 4619</p>
							<p>Copyright © {this.printCurrentYear()} Curate Mobile Ltd. All Rights Reserved. <Link aria-label="Site Map. Internal link to site map." onKeyDown={handleLinkKeyDown} to="/SiteMap">Site&nbsp;Map</Link></p>
						</div>
					</div>
				</div>
				<div className="footer__mobile">
					<div className="footer__mobile__social" role="list">
						<a aria-label="Facebook icon. External link to Curate's Facebook page. Selecting this will open a new window." href="https://www.facebook.com/curatemobile/" onBlur={() => { this.setState({ facebookIcon: 'white' }) }} onFocus={() => { this.setState({ facebookIcon: 'colour' }) }} rel="noopener noreferrer" role="listitem" target="_blank">
							<img src={this.state.facebookIcon === 'white' ? iconSocialFacebook : iconSocialFacebookColor} alt="Facebook Icon." onMouseEnter={() => { this.setState({ facebookIcon: 'colour' }) }} onMouseLeave={() => { this.setState({ facebookIcon: 'white' }) }} />
						</a>
						<a aria-label="Instagram icon. External link to Curate's Instagram page. Selecting this will open a new window." href="https://www.instagram.com/curatemobile/" onBlur={() => { this.setState({ instagramIcon: 'white' }) }} onFocus={() => { this.setState({ instagramIcon: 'colour' }) }} rel="noopener noreferrer" role="listitem" target="_blank">
							<img src={this.state.instagramIcon === 'white' ? iconSocialInstagram : iconSocialInstagramColor} alt="Instagram Icon." onMouseEnter={() => { this.setState({ instagramIcon: 'colour' }) }} onMouseLeave={() => { this.setState({ instagramIcon: 'white' }) }} />
						</a>
						<a aria-label="Twitter icon. External link to Curate's Twitter page. Selecting this will open a new window." href="https://twitter.com/curatemobileltd" onBlur={() => { this.setState({ twitterIcon: 'white' }) }} onFocus={() => { this.setState({ twitterIcon: 'colour' }) }} rel="noopener noreferrer" role="listitem" target="_blank">
							<img src={this.state.twitterIcon === 'white' ? iconSocialTwitter : iconSocialTwitterColor} alt="Twitter Icon." onMouseEnter={() => { this.setState({ twitterIcon: 'colour' }) }} onMouseLeave={() => { this.setState({ twitterIcon: 'white' }) }} />
						</a>
						<a aria-label="LinkedIn icon. External link to Curate's LinkedIn page. Selecting this will open a new window." href="https://www.linkedin.com/company/curate-mobile-ltd/" onBlur={() => { this.setState({ linkedinIcon: 'white' }) }} onFocus={() => { this.setState({ linkedinIcon: 'colour' }) }} rel="noopener noreferrer" role="listitem" target="_blank">
							<img src={this.state.linkedinIcon === 'white' ? iconSocialLinkedin : iconSocialLinkedinColor} alt="LinkedIn Icon." onMouseEnter={() => { this.setState({ linkedinIcon: 'colour' }) }} onMouseLeave={() => { this.setState({ linkedinIcon: 'white' }) }} />
						</a>
					</div>
					<div className="footer__mobile__links" role="list">
						<Link aria-label="Solutions. Internal link to solutions page." onKeyDown={handleLinkKeyDown} role="listitem" to="/Solutions">Solutions</Link>
						<Link aria-label="Products. Internal link to products page." onKeyDown={handleLinkKeyDown} role="listitem" to="/Products">Products</Link>
						<Link aria-label="Contact. Internal link to contact page." onKeyDown={handleLinkKeyDown} role="listitem" to="/Contact">Contact</Link>
						<a aria-label="Blog. External link to Curate's blog." href="https://insights.curatemobile.com" role="listitem">Blog</a>
						<Link aria-label="About Us. Internal link to company page." onKeyDown={handleLinkKeyDown} role="listitem" to="/Company">About Us</Link>
						<Link aria-label="Privacy Policy. Internal link to privacy policy page." onKeyDown={handleLinkKeyDown} role="listitem" to="/PrivacyPolicy">Privacy Policy</Link>
					</div>
					<div className="footer__mobile__logos">
						<div className="footer__mobile__logos__certifications" role="list">
							<a aria-label="Publisher Login. External link to Curate's publisher platform. Selecting this will open a new window." href="https://console.curatemobile.com/publisher/login" rel="noopener noreferrer" role="listitem" target="_blank">Publisher Login</a>
						</div>
						<div className="footer__mobile__logos__badge">
							<img alt="Great place to work badge. Certified Cananda." src={logoGreatPlacesToWork} />
							<img alt="Group of four certification logos. The items are official member CAAF - Coalition against ad fraud, GDPR compliance, IAB Canada membership, and Level Double-A conformance, W3C WAI Web Content Accessibility Guidelines 2.1" role="listitem" src={logoCertifications} />
						</div>
					</div>
					<div className="footer__mobile__details">
						<p>
							info@curatemobile.com <span className="footer__mobile__details__divider">•</span> 1&nbsp;416&nbsp;840&nbsp;4619 <span className="footer__mobile__details__divider">•</span> <Link aria-label="Site Map. Internal link to site map." onKeyDown={handleLinkKeyDown} to="/SiteMap">Site&nbsp;Map</Link>
						</p>
					</div>
					<div className="footer__mobile__legal">
						<p>Copyright © {this.printCurrentYear()} Curate Mobile Ltd. All Rights Reserved. <Link aria-label="Privacy Policy. Internal link to privacy policy page." onKeyDown={handleLinkKeyDown} to="/PrivacyPolicy">Privacy&nbsp;Policy</Link>. <Link aria-label="Site Map. Internal link to site map." onKeyDown={handleLinkKeyDown} to="/SiteMap">Site&nbsp;Map</Link></p>
					</div>
				</div>
				<div aria-label="Back to top of page button. Selecting this will scroll the page back to the top of the content." className="footer__scroll" onClick={this.scrollToTheTop} onKeyDown={this.scrollToTheTop} role="button">
					<img alt="Up arrow icon" src={iconBackToTopBlack} />
					<p>Back to top</p>
				</div>
				<div aria-label="Back to top of page." className="nav-skip" onKeyPress={this.skipToContent} role="dialog" tabIndex="0">Back to top of page.</div>
			</footer>
		);
	}
}

export default Footer;
