// eslint-disable-next-line
import React, { Component } from "react";

class ScrollToTopOnMount extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);

		if (window.location.pathname === '/Solutions') {
			document.querySelector('.dropdown-toggle-solutions').style.textShadow = '0px 0px 1px black';
		} else {
			document.querySelector('.dropdown-toggle-solutions').style.textShadow = 'none';
		}

		if (window.location.pathname === '/Products') {
			document.querySelector('.dropdown-toggle-products').style.textShadow = '0px 0px 1px black';
		} else {
			document.querySelector('.dropdown-toggle-products').style.textShadow = 'none';
		}

	}

	render() {
		return null;
	}
}

export default ScrollToTopOnMount;
