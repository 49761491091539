// Dependencies
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Checkbox, Textarea, TextInput } from 'react-materialize';
import Helmet from 'react-helmet';
import axios from 'axios';
// Local files
import { doesUserAllowAnimation, animateElement, animateElements, removeAnimation, handleLinkKeyDown } from '../helpers';

// Style
import '../Styles/Contact.css';

// Components
import CookiesBanner from "../Components/CookiesBanner";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";

class Contact extends Component {
	constructor() {
		super();
		this.state = {
			formFirstName: '',
			formLastName: '',
			formCompany: '',
			formJobTitle: '',
			formEmail: '',
			formPhone: '',
			formMessage: '',
			formConsent: false,
			emailSent: false,
		};
	}

	componentDidMount() {
		const checkbox = document.querySelector('.contact-form__checkbox');
		checkbox.addEventListener('keydown', function (event) {
			if (event.keyCode === 13) {
				event.preventDefault();
				checkbox.click();
			}
		});
		document.getElementById('consent-form-consent').required = 'true';

		if (doesUserAllowAnimation()) {
			animateElements(document.querySelectorAll('.animate__fade-in'), 'fadeIn');
		} else {
			removeAnimation(document.querySelectorAll('.animate__fade-in'), 'animate__fade-in');
		}
	}

	handleConsent = (e) => {
		(this.state.formConsent) ? this.setState({ formConsent: false }) : this.setState({ formConsent: true });
	};

	handleInput = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleValidation = (e) => {
		if (e.target.id === 'consent-form-email') {
			const invalid = (e.target.value.indexOf('@') < 0);
			const emailAlertDiv = document.querySelector('.contact-form__error');
			const emailInput = document.getElementById('consent-form-email');

			if (e.target.value.length > 0 && invalid) {
				emailInput.setAttribute('aria-invalid', 'true');
				emailInput.style.borderBottom = '1px solid #EF221F';
				emailInput.style.boxShadow = '0 1px 0 0 #EF221F';

				emailAlertDiv.setAttribute('aria-hidden', 'false');
				emailAlertDiv.style.opacity = '1';
			} else {
				emailInput.setAttribute('aria-invalid', 'false');
				emailInput.style.borderBottom = '1px solid #19154D';
				emailInput.style.boxShadow = '0 1px 0 0 #19154D';

				emailAlertDiv.setAttribute('aria-hidden', 'true');
				emailAlertDiv.style.opacity = '0';
			}
		}
	};

	getBlogPosts = (e) => {
		httpGetAsync('https://insights.curatemobile.com/wp-json/wp/v2/posts?per_page=2', function (data) {
			const blogPosts = JSON.parse(data);

			const htmlElement = document.getElementById('contact-form-blog-items');
			let htmlContent = `
				<div>
					<a aria-label="External link to Curate blog post titled ${blogPosts[0].title.rendered}. Selecting this will open a new window." href="${blogPosts[0].link}" rel="noopener noreferrer" target="_blank">
						<img src="${blogPosts[0].jetpack_featured_media_url}" />
						<p>${blogPosts[0].title.rendered}</p>
						<p>${new Date(blogPosts[0].date).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })}</p>
					</a>
				</div>
				<div>
					<a aria-label="External link to Curate blog post titled ${blogPosts[1].title.rendered}. Selecting this will open a new window." href="${blogPosts[1].link}" rel="noopener noreferrer" target="_blank">
						<img src="${blogPosts[1].jetpack_featured_media_url}" />
						<p>${blogPosts[1].title.rendered}</p>
						<p>${new Date(blogPosts[1].date).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })}</p>
					</a>
				</div>
			`;

			htmlElement.innerHTML = htmlContent;

			document.querySelector('.contact-form__confirmation__alert').focus();
		});

		function httpGetAsync(theUrl, callback) {
			let xmlHttp = new XMLHttpRequest();
			xmlHttp.onreadystatechange = function () {
				if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
					callback(xmlHttp.responseText);
				}
			};
			xmlHttp.open("GET", theUrl, true); // true for asynchronous
			xmlHttp.send(null);
		}
	};

	onSubmit = (e) => {
		e.preventDefault();

		const {
			formFirstName,
			formLastName,
			formCompany,
			formJobTitle,
			formEmail,
			formPhone,
			formMessage,
			formConsent
		} = this.state;

		// DEVELOPMENT
		// this.setState({ emailSent: true }, () => {
		// 	this.getBlogPosts();
		// });

		// PRODUCTION
		axios.post('https://formspree.io/mdrjlepm', {
			formFirstName,
			formLastName,
			formCompany,
			formJobTitle,
			formEmail,
			formPhone,
			formMessage,
			formConsent
		}).then((resp) => {
			this.setState({ emailSent: true });
			this.getBlogPosts();
		});
	};

	render() {
		return (
			<div id="contact-page">
				<CookiesBanner />
				<ScrollToTopOnMount />

				<section aria-label="Contact us." className="contact-header">
					<div aria-label="Background image of a group of people interacting with their mobile phones, tablets, and laptops." className="contact-header__hero"></div>
					<h1 aria-hidden="false" className={this.state.emailSent ? 'animate__fade-in contact-form--hide' : 'animate__fade-in contact-form--show'} onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1"><span className="font--bold">Contact</span> Us.</h1>
					<h1 className={this.state.emailSent ? 'animate__fade-in contact-form--show' : 'animate__fade-in contact-form--hide'} onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Thank <span className="font--bold">You.</span></h1>
					<div className="divider__horizontal contact-header__divider"></div>
				</section>

				<div aria-label="Contact form." className={this.state.emailSent ? 'contact-form contact-form--hide' : 'contact-form contact-form--show'}>

					<div aria-hidden={true} className="contact-form__error" role="alert">
						<p>Invalid email address format.<br />Ensure your email follows standard convention (e.g. name@domain.com).</p>
					</div>

					<form className="animate__fade-in" onSubmit={this.onSubmit}>
						<TextInput
							aria-label="First name contact form field. Used for identification in correspondence."
							aria-required="true"
							id="consent-form-first-name"
							label="FIRST NAME"
							name="formFirstName"
							noLayout={true}
							onChange={this.handleInput}
							required={true}
							tabIndex="0"
							validate={false}
						/>
						<TextInput
							aria-label="Last name contact form field. Used for identification in correspondence."
							aria-required="true"
							id="consent-form-last-name"
							label="LAST NAME"
							name="formLastName"
							noLayout={true}
							onChange={this.handleInput}
							required={true}
							tabIndex="0"
							validate={false}
						/>
						<TextInput
							aria-label="Company contact form field. Used for identification in correspondence."
							aria-required="true"
							id="consent-form-company"
							label="COMPANY"
							name="formCompany"
							noLayout={true}
							onChange={this.handleInput}
							required={true}
							tabIndex="0"
							validate={false}
						/>
						<TextInput
							aria-label="Job title contact form field. Used for identification in correspondence."
							aria-required="true"
							id="consent-form-job"
							label="JOB TITLE"
							name="formJobTitle"
							noLayout={true}
							onChange={this.handleInput}
							required={true}
							tabIndex="0"
							validate={false}
						/>
						<TextInput
							aria-label="Email contact form field. Used for correspondence type."
							aria-required="true"
							email={true}
							id="consent-form-email"
							label="EMAIL"
							name="formEmail"
							onBlur={this.handleValidation}
							noLayout={true}
							onChange={this.handleInput}
							required={true}
							tabIndex="0"
						/>
						<TextInput
							aria-label="Phone contact form field. Used for correspondence type."
							aria-required="true"
							id="consent-form-phone"
							label="PHONE NUMBER"
							name="formPhone"
							noLayout={true}
							onChange={this.handleInput}
							required={true}
							validate={false}
							tabIndex="0"
						/>
						<Textarea
							aria-label="Message contact form field labeled 'how can we help?'. Used to identify topic for correspondence."
							aria-required="true"
							id="consent-form-message"
							label="HOW CAN WE HELP?"
							name="formMessage"
							noLayout={true}
							onChange={this.handleInput}
							required={true}
							validate={false}
							tabIndex="0"
						/>
						<div className="contact-form__consent">
							<Checkbox
								aria-label="Checkbox form field. I consent to my submitted data being collected and stored. Used to confirm consent that Curate will receive your contact form data."
								aria-required="true"
								className="contact-form__checkbox"
								filledIn
								id="consent-form-consent"
								label="I consent to my submitted data being collected and stored."
								onChange={this.handleConsent}
								required={true}
								tabIndex="0"
								value="I consent"
							/>
						</div>

						<div className="contact-form__submit" tabIndex="0">
							<input aria-label="Send button. Selecting this will send an email to Curate Mobile with the information you provided in the contact form, and will also update the context of this page to replace the form element with Curate's latest blog posts." className="contact-form__submit__button" id="consent-form-submit" type="submit" value="SEND" />
							<p className="contact-form__submit__legal" role="contentinfo">Our website collects visitor data such as location and site navigation to build the best experience for users on our website, if you would like to opt out please visit our <Link aria-label="Privacy Policy. Internal link to privacy policy page." onKeyDown={handleLinkKeyDown} role="listitem" to="/PrivacyPolicy">Privacy Policy</Link>.</p>
						</div>
					</form>
				</div>

				<div aria-label="Contact form confirmation of submission." className={this.state.emailSent ? 'contact-form__confirmation contact-form--show' : 'contact-form__confirmation contact-form--hide'}>
					<p aria-hidden={false} className="contact-form__confirmation__alert" role="alert">Form successfully sent. Page content has been updated.</p>
					<p id="form-confirmation-message" tabIndex="0">Your note has been sent and someone from our team will get back to you within 48-hours.</p>
				</div>

				<section aria-label="Our blog." className={this.state.emailSent ? 'contact-form__confirmation__blog contact-form--show' : 'contact-form__confirmation__blog contact-form--hide'}>
					<h2 aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">See Our <span className="font--bold">Blog.</span></h2>
					<div className="contact-form__confirmation__blog__items" id="contact-form-blog-items">
					</div>
				</section>

				<Helmet title='Contact' />
			</div>
		);
	}
}

export default Contact;
