// Dependencies
import React, { Component } from 'react';
import Helmet from 'react-helmet';
// Local files
import { doesUserAllowAnimation, setupAnimationObserver, animateElement, removeAnimation } from '../helpers';

// Style
import '../Styles/Solutions.css';

// Images
import iconCheckmark from '../Images/solutions/icon-solutions-checkmark.png';

import graphicPhone from '../Images/solutions/graphic-scalable-phone.png';
import graphicPhoneMobile from '../Images/solutions/graphic-scalable-phone-mobile-v2.png';

import iconDataDriven from '../Images/solutions/icon-purchase-intent-data-driven.png';
import iconPrivacy from '../Images/solutions/icon-purchase-intent-privacy.png';
import iconCreative from '../Images/solutions/icon-purchase-intent-creative.png';
import iconHouse from '../Images/solutions/icon-purchase-intent-house.png';
import iconDsp from '../Images/solutions/icon-purchase-intent-dsp.png';
import iconAlgorithm from '../Images/solutions/icon-purchase-intent-algorithm.png';

import stockWoman from '../Images/solutions/stock-insights-woman-on-phone-v3.jpg';

import iconGear from '../Images/solutions/icon-addressing-gear.png';
import iconHands from '../Images/solutions/icon-addressing-hands.png';
import iconCloud from '../Images/solutions/icon-addressing-cloud.png';
import iconSocialMedia from '../Images/solutions/icon-social-collection.png';

// Videos
import heroVideo from '../Videos/solutions/stock-hero-v3.mp4';

// Components
import CookiesBanner from "../Components/CookiesBanner";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";
import VerticalDivider from '../Components/VerticalDivider';
import CarouselSolutions from '../Components/CarouselSolutions';

class Solutions extends Component {
	componentDidMount() {
		const section = window.location.hash;
		if (section === '#AppMarketing') {
			const yPos = document.querySelector('.solutions-hero').getBoundingClientRect().height;
			window.setTimeout(() => {
				document.querySelector('.solutions-growth-marketing h2').focus();
				window.scrollTo({
					top: yPos,
					left: 0,
					behavior: 'smooth'
				});
			}, 250);
		} else if (section === '#SocialPerformance') {
			let yPos = document.querySelector('.solutions-hero').getBoundingClientRect().height;
			yPos += document.querySelector('.solutions-growth-marketing').getBoundingClientRect().height;
			yPos += document.querySelector('.solutions-scalable').getBoundingClientRect().height;
			yPos += document.querySelector('.solutions-scalable').getBoundingClientRect().height;
			// yPos += document.querySelector('.solutions-purchase-intent').getBoundingClientRect().height;
			// yPos += document.querySelector('.solutions-insights').getBoundingClientRect().height;
			// yPos += document.querySelector('.solutions-addressing').getBoundingClientRect().height;
			window.setTimeout(() => {
				document.querySelector('.solutions-social__top h2').focus();
				window.scrollTo({
					top: yPos,
					left: 0,
					behavior: 'smooth'
				});
			}, 250);
		}

		if (window.innerWidth <= 550) {
			const mobileElements = document.getElementsByClassName('solutions-scalable--mobile-only');
			for (let i = 0; i < mobileElements.length; i++) {
				mobileElements[i].setAttribute('aria-hidden', 'false');
			}

			const desktopElements = document.getElementsByClassName('solutions-scalable--desktop-only');
			for (let i = 0; i < desktopElements.length; i++) {
				desktopElements[i].setAttribute('aria-hidden', 'true');
			}
		}

		if (doesUserAllowAnimation()) {
			setupAnimationObserver(document.querySelectorAll('section'), 'animate__fade-in', 'fadeIn', 0.8);
			setupAnimationObserver(document.querySelectorAll('.solutions-purchase-intent__top'), 'animate__fade-in', 'fadeIn', 0.8);

			// animation for solutions-growth-marketing icons
			(function () {
				const animationOptions = {
					root: null,
					rootMargin: '0px',
					threshold: 0.9
				};

				let observerFadeInUp = new IntersectionObserver((element) => {
					const fadeDelay = 500;
					if (element[0].intersectionRatio > 0.8) {
						element[0].target.querySelectorAll('.animate__fade-in-up-1').forEach((child) => {
							animateElement(child, 'fadeInUp');
						});
						window.setTimeout(() => {
							element[0].target.querySelectorAll('.animate__fade-in-up-2').forEach((child) => {
								animateElement(child, 'fadeInUp');
							});
						}, fadeDelay);
						window.setTimeout(() => {
							element[0].target.querySelectorAll('.animate__fade-in-up-3').forEach((child) => {
								animateElement(child, 'fadeInUp');
							});
						}, fadeDelay * 2);
					}
				}, animationOptions);

				observerFadeInUp.observe(document.querySelector('.solutions-growth-marketing__inner__right'));
			})();



			// animation for solutions-addressing icons
			(function () {
				const animationOptions = {
					root: null,
					rootMargin: '0px',
					threshold: 0.9
				};

				let observerFadeInUp = new IntersectionObserver((element) => {
					const fadeDelay = 500;
					if (element[0].intersectionRatio > 0.8) {
						animateElement(element[0].target.querySelector('.animate__fade-in-up-1'), 'fadeInUp');
						window.setTimeout(() => {
							animateElement(element[0].target.querySelector('.animate__fade-in-up-2'), 'fadeInUp');
						}, fadeDelay);
						window.setTimeout(() => {
							animateElement(element[0].target.querySelector('.animate__fade-in-up-3'), 'fadeInUp');
						}, fadeDelay * 2);
					}
				}, animationOptions);


			})();
		} else {
			removeAnimation(document.querySelectorAll('.animate__fade-in'), 'animate__fade-in');
			for (let i = 1; i <= 6; i++) {
				removeAnimation(document.querySelectorAll(`.animate__fade-in-up-${i}`), `animate__fade-in-up-${i}`);
			}
		}
	}

	videoControlOnClick = () => {
		const videoElement = document.querySelector('.solutions-video-hero__video');
		return videoElement.paused ? videoElement.play() : videoElement.pause();
	};

	render() {
		return (
			<div id="solutions-page">
				<CookiesBanner />
				<ScrollToTopOnMount />

				<section aria-label="Background video of the Earth at night." className="solutions-hero">
					<video aria-label="Video element controls: To pause the video, use the enter key or mouse click. Video description: A scenic video showing the Earth at night, with city lights visible. There is no audio and the video plays on an endless loop." autoPlay className="solutions-video-hero__video" loop onClick={this.videoControlOnClick} muted playsInline>
						<source src={heroVideo} type="video/mp4" />
						Our apologies, your browser appears to not support video.
					</video>
					<h1 aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">A mobile affiliate marketplace offering end-to-end growth solutions.</h1>
					<div className="divider__horizontal solutions-hero__divider"></div>
				</section>

				<section aria-label="Growth marketing solutions." className="solutions-growth-marketing">
					<div className="solutions-growth-marketing__inner">
						<div className="solutions-growth-marketing__inner__left">
							<div className="solutions-growth-marketing__inner__left__divider"></div>
							<div className="solutions-growth-marketing__inner__left__text">
								<h2 aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1"><span className="font--bold">Growth</span> Marketing Solutions.</h2>
								<p aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1"><span className="font--bold">Acquire</span> valuable users to generate optimal ROAS and grow lifetime value through effective <span className="font--bold">retargeting</span> solutions based on real users, verified through our proprietary anti-fraud&nbsp;tools.</p>
							</div>
						</div>
						<div className="solutions-growth-marketing__inner__right">
							<div className="solutions-growth-marketing__inner__right__item">
								<div>
									<img alt="Checkmark icon." className="animate__fade-in-up-1 component-vertical-divider-anchor" src={iconCheckmark} />
								</div>
								<p aria-hidden="false" className="animate__fade-in-up-1" onFocus={((event) => animateElement(event.target, 'fadeInUp'))} tabIndex="-1">Build & deliver <br />customized creative</p>
							</div>
							<div className="solutions-growth-marketing__inner__right__item">
								<div>
									<img alt="Checkmark icon." className="animate__fade-in-up-2" src={iconCheckmark} />
								</div>
								<p aria-hidden="false" className="animate__fade-in-up-2" onFocus={((event) => animateElement(event.target, 'fadeInUp'))} tabIndex="-1">Drive incremental growth & <br />intent-based conversions</p>
							</div>
							<div className="solutions-growth-marketing__inner__right__item">
								<div>
									<img alt="Checkmark icon." className="animate__fade-in-up-3" src={iconCheckmark} />
								</div>
								<p aria-hidden="false" className="animate__fade-in-up-3" onFocus={((event) => animateElement(event.target, 'fadeInUp'))} tabIndex="-1">Retarget users to drive ongoing <br />interest & re-engagement</p>
							</div>
							<VerticalDivider />
						</div>
					</div>
				</section>

				<section aria-label="Scalable solutions for performance-based outcomes." className="solutions-scalable">
					<div className="solutions-scalable__inner">
						<h2 aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1"><span className="font--bold">Scalable solutions</span> for performance based outcomes.</h2>
						<div className="solutions-scalable__inner__rows">
							<div className="solutions-scalable__inner__rows__left">
								<div aria-hidden="false" className="animate__fade-in solutions-scalable__inner__rows__left__item" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">
									<p>Consumer aquisition</p>
									<div className="solutions-scalable__inner__rows__left__item__lines"></div>
								</div>
								<div aria-hidden="false" className="animate__fade-in solutions-scalable__inner__rows__left__item" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">
									<p>Full data transparency</p>
									<div className="solutions-scalable__inner__rows__left__item__lines"></div>
								</div>
								<div aria-hidden="false" className="animate__fade-in solutions-scalable__inner__rows__left__item" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">
									<p>Outcome Focused</p>
									<div className="solutions-scalable__inner__rows__left__item__lines"></div>
								</div>
							</div>
							<div aria-hidden="false" className="animate__fade-in solutions-scalable__inner__rows__center" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">
								<img alt="Screenshot of mobile phone displaying a generic login form." aria-hidden="false" className="solutions-scalable--desktop-only" src={graphicPhone} />
								<img alt="Screenshot of mobile phone displaying a generic login form." aria-hidden="true" className="solutions-scalable--mobile-only" src={graphicPhoneMobile} />
							</div>
							<div className="solutions-scalable__inner__rows__right">
								<div aria-hidden="true" className="animate__fade-in solutions-scalable--mobile-only solutions-scalable__inner__rows__right__item solutions-scalable__inner__rows__right__item--mobile" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">
									<div className="solutions-scalable__inner__rows__right__item__lines"></div>
									<p>Consumer aquisition</p>
								</div>
								<div aria-hidden="true" className="animate__fade-in solutions-scalable--mobile-only solutions-scalable__inner__rows__right__item solutions-scalable__inner__rows__right__item--mobile" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">
									<div className="solutions-scalable__inner__rows__right__item__lines"></div>
									<p>Full data transparency</p>
								</div>
								<div aria-hidden="true" className="animate__fade-in solutions-scalable--mobile-only solutions-scalable__inner__rows__right__item solutions-scalable__inner__rows__right__item--mobile" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">
									<div className="solutions-scalable__inner__rows__right__item__lines"></div>
									<p>Outcome Focused</p>
								</div>
								<div aria-hidden="false" className="animate__fade-in solutions-scalable__inner__rows__right__item" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">
									<div className="solutions-scalable__inner__rows__right__item__lines"></div>
									<p>Premium Mobile Inventory</p>
								</div>
								<div aria-hidden="false" className="animate__fade-in solutions-scalable__inner__rows__right__item" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">
									<div className="solutions-scalable__inner__rows__right__item__lines"></div>
									<p>Ad-fraud prevention</p>
								</div>
								<div aria-hidden="false" className="animate__fade-in solutions-scalable__inner__rows__right__item" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">
									<div className="solutions-scalable__inner__rows__right__item__lines"></div>
									<p>In-house media buying</p>
								</div>
							</div>
						</div>
					</div>
				</section>



				<section aria-label="Consumer journey insights." className="solutions-insights">
					<div className="solutions-insights__left">
						<h2 aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Consumer Journey <span className="font--bold">Insights</span>.</h2>
						<div className="divider__horizontal solutions-insights__left__divider"></div>
						<p aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Deepen your understanding of your consumers. Our customized consumer journey and campaign planning solutions provide actionable insights that translate to real-world results, reaching and surpassing advertiser objectives and&nbsp;KPIs.</p>
					</div>
					<div aria-label="Happy person looking at their mobile phone. The phone is visible in their glasses reflection." className="solutions-insights__right">
						<img alt="Happy person looking at their mobile phone. The phone is visible in their glasses reflection." src={stockWoman} />
					</div>
				</section>


				<section aria-label="Managing your social media is difficult." className="solutions-social" style={{ backgroundColor: "#FFFFFF" }}>
					<div className="solutions-social__top">
						<h2 aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeInUp'))} tabIndex="-1">Managing your<span className="font--bold"> social media</span> is difficult.</h2>
						<p aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeInUp'))} tabIndex="-1">Algorithms are constantly being updated. Save yourself the time and investment by working with Curate. We are a cost-effective, highly scalable, efficient, responsive, and proven partner for outsourcing your digital advertising and paid social media activity.</p>
						<p aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeInUp'))} tabIndex="-1">Curate is in the business of providing results! Focused on providing performance-based outcomes! We offer end-to-end social media solutions. From creative builds, audience identification, brand awareness, lead generation, engagement, and specific outcomes - Curate is your social performance partner!</p>
					</div>
					<div className="solutions-social__icons">
						<div aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeInUp'))} tabIndex="-1">
							<img alt="Icons of social media partners. From left to right: Facebook, Instagram, Pinterest, Reddit, Twitter, Tiktok, Snapchat, Youtube" src={iconSocialMedia} />
						</div>
					</div>
				</section>
				<Helmet title='Solutions' />
			</div>
		);
	}
}

export default Solutions;
