// Dependencies
import React, { Component } from 'react';
import Helmet from 'react-helmet';
// Local files
import { doesUserAllowAnimation, setupAnimationObserver, animateElement, animateElements, removeAnimation } from '../helpers';

// Style
import '../Styles/Company.css';

// Images
import imageMarcPorcelli from '../Images/company/image-executive-marc-porcelli.png';

import stockPerson from '../Images/company/stock-hiring.jpg';

// Components
import CookiesBanner from "../Components/CookiesBanner";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";
import Timeline from "../Components/Timeline";
import WorkTogether from "../Components/WorkTogether";
import Awards from "../Components/Awards";

class Company extends Component {
	componentDidMount() {
		if (doesUserAllowAnimation()) {
			setupAnimationObserver(document.querySelectorAll('section'), 'animate__fade-in', 'fadeIn', 0.8);
			//setupAnimationObserver(document.querySelectorAll('.company-team__top'), 'animate__fade-in', 'fadeIn', 0.8);
			//setupAnimationObserver(document.querySelectorAll('.company-team__executives'), 'animate__fade-in', 'fadeIn', 0.8);
		} else {
			removeAnimation(document.querySelectorAll('.animate__fade-in'), 'animate__fade-in');
			removeAnimation(document.querySelectorAll('.animate__fade-in-up'), 'animate__fade-in-up');
		}
	}

	render() {
		return (
			<div id="company-page">
				<CookiesBanner />
				<ScrollToTopOnMount />

				<section aria-label="Background image of happy person walking on the sidewalk listening to music from their mobile phone." className="company-hero">
					<h1 aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">We put people first. <span className="font--bold">Ours & Yours.</span></h1>
					<div className="divider__horizontal company-hero__divider"></div>
					<p aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1"> At Curate culture is key, we are building a company that is focused around people. Curate was built by industry veterans on a mission to bring transparency and ethical practices to the field of mobile advertising. We’re passionate about what we do and we hope it&nbsp;shows.</p>
				</section>

				<Timeline />



				<section aria-label="We are hiring." className="company-hiring">
					<div className="company-hiring__left">
						<h2 aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">We're <span className="font--bold">Hiring.</span></h2>
						<div className="divider__horizontal company-hiring__left__divider"></div>
						<p aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">We are committed to our people and culture and are looking for dynamic talented individuals to join us.</p>
						<a aria-label="See Jobs. External link to Curate's BambooHR page, listing current open positions. Selecting this will open a new window." className="text--uppercase" href="https://curate.bamboohr.com/jobs/" rel="noopener noreferrer" target="_blank">See&nbsp;Jobs</a>
					</div>
					<div aria-label="Background close up of a person interacting with their mobile phone." className="company-hiring__right">
						<img alt="Background close up of a person interacting with their mobile phone." src={stockPerson} />
					</div>
				</section>

				<WorkTogether />

				<Awards />

				<Helmet title='Company' />
			</div>
		);
	}
}

export default Company;
