import React, { Component } from "react";
import { Link } from "react-router-dom";

class Notification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isNotificationDisplayed: true,
      hashedText: 0,
      text: "🎯 Contact Us today to achieve outcomes-based results and ROAS!"
    };
  }

  removeNotificationBar = () => {
    if (!+localStorage.getItem("curate-notification")) {
      localStorage.setItem("curate-notification", this.state.hashedText);
    }

    this.setState({ isNotificationDisplayed: false });
  };

  hashString = (s) => {
    return s.split("").reduce(function(a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  };


  updateNotificationState = (text) => {
    this.setState({ hashedText: this.hashString(text) });

    const storedNotification = localStorage.getItem("curate-notification");

    if (storedNotification) {
      this.setState({ isNotificationDisplayed: false });
    } else {
      this.setState({ isNotificationDisplayed: true });
    }
  };

  componentDidMount() {
    this.updateNotificationState(this.state.text);
  }

  componentDidUpdate() {
    const storedNotification = localStorage.getItem("curate-notification");

    if (+storedNotification !== this.state.hashedText) {
      this.setState({ isNotificationDisplayed: true });
    }
    
    localStorage.setItem("curate-notification", this.state.hashedText);
  }

  render() {
    return (
      <section aria-label="Notification" className={`component-notification ${this.state.isNotificationDisplayed ? "" : "component-notification__hide"}`}>
        <p aria-hidden="false" className="component-notification__description">
          {this.state.text}
          <Link className="component-notification__link" to="/Contact">
            Yes, I’m ready!
          </Link>
          <span className="component-notification__close-button" onClick={this.removeNotificationBar}>
            ×
          </span>
        </p>
      </section>
    );
  }
}

export default Notification;
