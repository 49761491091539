// Dependencies
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// Local files
import { doesUserAllowAnimation, setupAnimationObserver, removeAnimation, animateElement } from "../helpers";

import HeroIcon from "../Images/partner-management/icon-partner-hero.png";
import CurateLogo from "../Images/skadnetwork-service/logo-curate.png";
import iconBidder from "../Images/products/icon-dsp-bidder.png";
import iconPayment from "../Images/partner-management/icon-partner-payment.png";
import iconTransparency from "../Images/partner-management/icon-partner-transparency.png";
import iconSetting from "../Images/solutions/icon-fraud-setting.png";
import iconExpert from "../Images/partner-management/icon-partner-expert.png";
import iconPrivacy from '../Images/partner-management/icon-partner-protection.png';

// Style
import "../Styles/SKAdService.css";
import "../Styles/PartnerManagement.css";

// Components
import CookiesBanner from "../Components/CookiesBanner";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";

class PartnerManagement extends Component {
  componentDidMount() {
    if (doesUserAllowAnimation()) {
      setupAnimationObserver(document.querySelectorAll("section"), "animate__fade-in", "fadeIn", 0.8);
      setupAnimationObserver(document.querySelectorAll('.benefits__title'), 'animate__fade-in', 'fadeIn', 0.8);
      setupAnimationObserver(document.querySelectorAll('.benefits__icons'), 'animate__fade-in', 'fadeIn', 0.8);

			(function () {
				const animationOptions = {
					root: null,
					rootMargin: '0px',
					threshold: 0.5
				};

        if (window.innerWidth <= 599) {
          for (let i = 1; i <= 6; i++) {
            const mobileElements = document.querySelector(`.animate__fade-in-up-${i}`)
            mobileElements.classList.remove(`animate__fade-in-up-${i}`);
          }
          document.querySelector('.benefits-button.animate__fade-in').classList.remove('animate__fade-in')
        }
				let observerFadeInUp = new IntersectionObserver((element) => {
					const fadeDelay = 350;
					if (element[0].intersectionRatio > 0.4) {
						if (window.innerWidth > 599 && window.innerWidth <= 799) {
							animateElement(element[0].target.querySelector('.animate__fade-in-up-1'), 'fadeInUp');
							animateElement(element[0].target.querySelector('.animate__fade-in-up-2'), 'fadeInUp');
							window.setTimeout(() => {
                animateElement(element[0].target.querySelector('.animate__fade-in-up-3'), 'fadeInUp');
                animateElement(element[0].target.querySelector('.animate__fade-in-up-4'), 'fadeInUp');
              }, fadeDelay);
              window.setTimeout(() => {
								animateElement(element[0].target.querySelector('.animate__fade-in-up-5'), 'fadeInUp');
								animateElement(element[0].target.querySelector('.animate__fade-in-up-6'), 'fadeInUp');
							}, fadeDelay * 2);
						} else if (window.innerWidth > 800){
							animateElement(element[0].target.querySelector('.animate__fade-in-up-1'), 'fadeInUp');
							animateElement(element[0].target.querySelector('.animate__fade-in-up-2'), 'fadeInUp');
              animateElement(element[0].target.querySelector('.animate__fade-in-up-3'), 'fadeInUp');
              window.setTimeout(() => {
							  animateElement(element[0].target.querySelector('.animate__fade-in-up-4'), 'fadeInUp');
								animateElement(element[0].target.querySelector('.animate__fade-in-up-5'), 'fadeInUp');
                animateElement(element[0].target.querySelector('.animate__fade-in-up-6'), 'fadeInUp');
              }, fadeDelay)
						}
					}
				}, animationOptions);

				observerFadeInUp.observe(document.querySelector('.benefits__icons'));
			})();
    } else {
      removeAnimation(document.querySelectorAll('.animate__fade-in'), 'animate__fade-in');
			for (let i = 1; i <= 6; i++) {
				removeAnimation(document.querySelectorAll(`.animate__fade-in-up-${i}`), `animate__fade-in-up-${i}`);
			}
    }
  }

  render() {
    return (
      <div id="partnert-management-page">
        <CookiesBanner />
        <ScrollToTopOnMount />

        <section
          aria-label="Partner management platform"
          className="skadservice-hero"
        >
          <div className="hero__img-container">
            <img src={HeroIcon} alt="Icon of a mobile device" />
          </div>
          <div className="hero__text">
            <h1 aria-hidden="false" tabIndex="-1" className="animate__fade-in">
              <span className="text-subtitle">NEW!</span>
              <span className="font--bold text-title">
                A win-win-win through transparency, attribution and scale!
              </span>
            </h1>
            <p aria-hidden="false" tabIndex="-1" className="animate__fade-in">
              Introducing our radically transparent approach to mobile
              performance marketing, inspired by our owned and operated
              technology, managed solutions, anti-fraud systems and intense
              focus on automation.
            </p>
          </div>
        </section>

        <section
          aria-label="Curate's role in partner management platform"
          className="skadservice-intro"
        >
          <h3 aria-hidden="false" tabIndex="-1" className="animate__fade-in">We’re not your average performance management platform.</h3>
          <h4 aria-hidden="false" tabIndex="-1" className="animate__fade-in">
            We offer a continuity pricing model that provides guaranteed ROAS
            for our partners.
          </h4>
        </section>

        <section
          aria-label="Partner management platform features"
          className="skadservice-benefits partner-benefits"
        >
          <div className="benefits__title">
            <h3 aria-hidden="false" tabIndex="-1" className="animate__fade-in">
              We feature:
            </h3>
          </div>
          <div className="benefits__icons">
            <div aria-hidden="false" className="benefit animate__fade-in-up-1">
              <div className="img-container">
                <img
                  src={iconBidder}
                  alt="Icon of a mobile phone displaying an advertisement."
                />
              </div>
              <p>
                Partner recruitment, communication tracking, custom pricing
                tiers. Flexible prices models (CPA, CPI, Rev-share) all
                mobile-first!
              </p>
            </div>
            <div aria-hidden="false" className="benefit animate__fade-in-up-2">
              <div className="img-container">
                <img
                  src={iconPayment}
                  alt="Icon of a credit card."
                />
              </div>
              <p>Payment management</p>
            </div>
            <div aria-hidden="false" className="benefit animate__fade-in-up-3">
              <div className="img-container">
                <img
                  src={iconTransparency}
                  alt="Icon of a magnifying glass looking through a paper"
                />
              </div>
              <p>
                Full partner level transparency, no affiliate Id’s here, full
                partner names
              </p>
            </div>
            <div aria-hidden="false" className="benefit animate__fade-in-up-4">
              <div className="img-container">
                <img src={iconSetting} alt="Icon of a gear" />
              </div>
              <p>
                Levels of service to meet your needs: whether you’re used to
                self-serve or fully-managed service, we have you covered
              </p>
            </div>
            <div aria-hidden="false" className="benefit animate__fade-in-up-5">
              <div className="img-container">
                <img
                  src={iconExpert}
                  alt="Icon of a hand holding a credit card."
                />
              </div>
              <p>
                Expertise: through decades of experience in the mobile industry,
                we’ve had to lead by example and are always at the forefront of
                key issues shaping our industry
              </p>
            </div>
            <div aria-hidden="false" className="benefit animate__fade-in-up-6">
              <div className="img-container">
                <img
                  src={iconPrivacy}
                  alt="Icon of a cloud and locked padlock."
                />
              </div>
              <p>
                Transparency & Brand safety: using our own anti-fraud tools as
                well as 3rd party integrations
              </p>
            </div>
          </div>
          <Link
            aria-label="Contact us. Internal link to contact page."
            className="benefits-button animate__fade-in"
            to="/contact"
          >
            Contact Us Today!
          </Link>
        </section>

        <section
          aria-label="The benefits of joining Curate"
          className="skadservice-vision"
        >
          <img src={CurateLogo} alt="Curate Logo" />
          <p aria-hidden="false">
            Empowering every mobile marketer to succeed with confidence in a
            mobile consumer economy.
          </p>
        </section>
        <Helmet title="Partner management platform" />
      </div>
    );
  }
}

export default PartnerManagement;
