// Dependencies
import React, { Component } from 'react';
// Local files
import { doesUserAllowAnimation, animateElement, removeAnimation } from '../helpers';

class VerticalDivider extends Component {
  	componentDidMount() {
		window.addEventListener('resize', this.positionDivider, false);
		window.addEventListener('scroll', this.positionDivider, false);

		this.positionDivider();

		if (doesUserAllowAnimation()) {
			animateElement(document.querySelector('.animate__fade-in'), 'fadeIn');
		} else {
			removeAnimation(document.querySelectorAll('.animate__fade-in'), 'animate__fade-in');
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.positionDivider, false);
		window.removeEventListener('scroll', this.positionDivider, false);
	}

	positionDivider() {
		const anchorStyle = document.querySelector('.component-vertical-divider-anchor').getBoundingClientRect();
		const dividerElement = document.querySelector('.solutions-growth-marketing__vertical-divider');
		dividerElement.style.left = `${anchorStyle.x + (anchorStyle.width / 2)}px`;
		dividerElement.style.top = `${anchorStyle.y + (anchorStyle.height / 2)}px`;
	}

	render() {
		return (
			<div className="animate__fade-in solutions-growth-marketing__vertical-divider"></div>
		);
	}
}

export default VerticalDivider;
