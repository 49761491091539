// animations | only use if prefers-reduced-motion === false
export function doesUserAllowAnimation() {
	return window.matchMedia('(prefers-reduced-motion: no-preference)').matches;
}

export function setupAnimationObserver(mainElement, targetAnimation, animationClass, threshold) {
	const animationOptions = {
		root: null,
		rootMargin: '0px',
		threshold: 0.9
	};

	let observerFadeIn = new IntersectionObserver((parents) => {
		parents.forEach(parent => {
			if (parent.intersectionRatio > threshold) {
				parent.target.querySelectorAll(`.${targetAnimation}`).forEach((child) => {
					animateElement(child, animationClass);
				});
			}
		});
	}, animationOptions);

	const observeElementsForAnimation = function(elements) {
		elements.forEach(element => {
			observerFadeIn.observe(element);
		});
	};

	observeElementsForAnimation(mainElement);
}

export function animateAndRemoveElement(node, animationName, callback) {
	node.classList.add('animated', animationName);

    function handleAnimationEnd() {
        node.classList.remove('animated', animationName);
        node.removeEventListener('animationend', handleAnimationEnd);

        if (typeof callback === 'function') callback();
    }

    node.addEventListener('animationend', handleAnimationEnd);
}

export function animateElement(element, animationClass, callback) {
	element.classList.add('animated', animationClass);
	if (animationClass === 'pulse') {
		window.setTimeout(() => {
			element.classList.remove('animated', 'pulse');
		}, 1000);
	}

	function myCallback() {
		if (typeof callback === 'function') callback();
	}

	element.addEventListener('animationend', myCallback);
}

export function animateElements(elements, animationClass, callback) {
	elements.forEach(element => {
		element.classList.add('animated', animationClass);
	});
}

export function removeAnimation(mainElement, targetAnimation) {
	mainElement.forEach(element => {
		element.classList.remove(targetAnimation);
	});
}

// keyboard input
export function handleLinkKeyDown(event) {
	if (event.key === 'Enter') {
		window.setTimeout(() => {
			document.querySelector('.nav-skip').focus();
		}, 100);
	}
}
