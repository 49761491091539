import 'core-js/es/set';
import 'core-js/es/map';
import React from 'react';
import ReactDOM from 'react-dom';
import './Styles/index.css';
import App from './App';
import { unregister } from './registerServiceWorker';

const rootEl = document.getElementById('root');
ReactDOM.render(<App />, rootEl);

unregister();
