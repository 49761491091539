// Dependencies
import React, { Component } from 'react';
// eslint-disable-next-line
import { NavLink, Link } from "react-router-dom";
import { Icon } from 'react-materialize';
// Local files
import { handleLinkKeyDown } from '../../helpers';
import Notification from '../Notification';

// Images
import Logo from './navbar-logo-curate-black-v3.svg';
import Hamburger from './navbar-icon-hamburger-white-v2.svg';
import XIcon from './navbar-icon-x-white.svg';

class NavBar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			mobileFlyoutOpen: false,
			mobileLoginSubFlyoutOpen: false,
			mobileSolutionsSubFlyoutOpen: false,
			mobileTechStackFlyoutOpen: false
		};
	}

	closeMobileNavigation = () => {
		this.setState({ mobileFlyoutOpen: false });
	}

	toggleMobileLoginNavigation = () => {
		this.setState({ mobileLoginSubFlyoutOpen: !this.state.mobileLoginSubFlyoutOpen });
	}

	toggleMobileSolutionsNavigation = () => {
		this.setState({ mobileSolutionsSubFlyoutOpen: !this.state.mobileSolutionsSubFlyoutOpen });
	}

	toggleMobileTeckStackNavigation = () => {
		this.setState({ mobileTechStackFlyoutOpen: !this.state.mobileTechStackFlyoutOpen });
	}

	lockTab = (e) => {
		if (e.key === 'Tab') {
			document.getElementById('nav-flyout-logo').focus();
		}
	};

	printCurrentPage = () => {
		const currentPathname = window.location.pathname;
		const currentPage = (currentPathname.length > 1) ? currentPathname.substr(1) : 'Home';
		return currentPage;
	};

	skipToContent = (e) => {
		this.setState({ mobileFlyoutOpen: false });
		document.getElementById('main-content').style.visibility = 'visible';
		document.getElementById('main-content').focus();
	};

	handleLinkSectionKeyDown = (e) => {
		if (e.key === 'Enter') {
			if (window.location.pathname === '/Solutions' && e.target.href.search('/Solutions') !== -1) {
				e.preventDefault();
				if (e.target.href.search('#AppMarketing') !== -1) {
					window.setTimeout(() => {
						document.querySelector('.solutions-growth-marketing h2').focus();
					}, 250);
				} else if (e.target.href.search('#BrandMarketing') !== -1) {
					window.setTimeout(() => {
						document.querySelector('.solutions-purchase-intent__top h2').focus();
					}, 250);
				} else if (e.target.href.search('#SocialMedia') !== -1) {
					window.setTimeout(() => {
						document.querySelector('.solutions-social h2').focus();
					}, 250);
				}
			} else if (window.location.pathname === '/TechStack' && e.target.href.search('/TechStack') !== -1) {
				e.preventDefault();
				if (e.target.href.search('#GrowthPlatform') !== -1) {
					window.setTimeout(() => {
						document.querySelector('.products-dsp__top h2').focus();
					}, 250);
				} else if (e.target.href.search('#MobileDSP') !== -1) {
					window.setTimeout(() => {
						document.querySelector('.products-dsp__title h2').focus();
					}, 250);
				}
			}
		}
	};

	handleSamePageLink = (e) => {
		if (window.location.pathname === '/Solutions') {
			if (e.target.href.search('#AppMarketing') !== -1) {
				const yPos = document.querySelector('.solutions-hero').getBoundingClientRect().height;
				window.setTimeout(() => {
					window.scrollTo({
						top: yPos,
						left: 0,
						behavior: 'smooth'
					});
				}, 250);
			} else if (e.target.href.search('#BrandMarketing') !== -1) {
				let yPos = document.querySelector('.solutions-hero').getBoundingClientRect().height;
				yPos += document.querySelector('.solutions-growth-marketing').getBoundingClientRect().height;
				yPos += document.querySelector('.solutions-scalable').getBoundingClientRect().height;
				window.setTimeout(() => {
					window.scrollTo({
						top: yPos,
						left: 0,
						behavior: 'smooth'
					});
				}, 250);
			} else if (e.target.href.search('#SocialPerformance') !== -1) {
				let yPos = document.querySelector('.solutions-hero').getBoundingClientRect().height;
				yPos += document.querySelector('.solutions-growth-marketing').getBoundingClientRect().height;
				yPos += document.querySelector('.solutions-scalable').getBoundingClientRect().height;
				yPos += document.querySelector('.solutions-scalable').getBoundingClientRect().height;
				yPos += document.querySelector('.solutions-purchase-intent').getBoundingClientRect().height;
				yPos += document.querySelector('.solutions-insights').getBoundingClientRect().height;
				yPos += document.querySelector('.solutions-addressing').getBoundingClientRect().height;
				window.setTimeout(() => {
					window.scrollTo({
						top: yPos,
						left: 0,
						behavior: 'smooth'
					});
				}, 250);
			} else {
				window.setTimeout(() => {
					window.scrollTo({
						top: 0,
						left: 0,
						behavior: 'smooth'
					});
				}, 250);
			}
		} else if (window.location.pathname === '/TechStack') {
			if (e.target.href.search('#GrowthPlatform') !== -1) {
				const yPos = document.querySelector('.products-hero').getBoundingClientRect().height;
				window.setTimeout(() => {
					window.scrollTo({
						top: yPos,
						left: 0,
						behavior: 'smooth'
					});
				}, 250);
			} else if (e.target.href.search('#MobileDSP') !== -1) {
				let yPos = document.querySelector('.products-hero').getBoundingClientRect().height;
				yPos += document.querySelector('.products-console').getBoundingClientRect().height;
				window.setTimeout(() => {
					window.scrollTo({
						top: yPos,
						left: 0,
						behavior: 'smooth'
					});
				}, 250);
			} else {
				window.setTimeout(() => {
					window.scrollTo({
						top: 0,
						left: 0,
						behavior: 'smooth'
					});
				}, 250);
			}
		}
	};

	toggleMobileNavigation = (e) => {
		if (e.target.id === "mobile-flyout--trigger" || e.target.id === "nav-close") {
			e.preventDefault();
		}

		if (e.key === 'Enter') {
			if (e.target.id === 'nav-flyout-links') {
				// the timeout is to allow the focus to work as we have a delayed animation effect of revealing/hiding the flyout
				window.setTimeout(() => {
					if (document.querySelector('.nav__hamburger')) {
						document.querySelector('.nav__hamburger').focus();
					} else {
						document.querySelector('.nav__inner__hamburger').focus();
					}
				}, 100);
			} else {
				window.setTimeout(() => {
					document.getElementById('nav-flyout-links').focus();
				}, 100);
			}
		}

		this.setState({
			mobileFlyoutOpen: !this.state.mobileFlyoutOpen,
			mobileLoginSubFlyoutOpen: false
		});
	};

	render() {
		return (
			<div>

				<div aria-label="Skip to main content." className="nav-skip" onKeyPress={this.skipToContent} role="dialog" tabIndex="0">{this.printCurrentPage()} page.<br />Skip to main content.</div>

				<nav arial-label="Navigation bar." className="nav">
					<div className="nav__inner">
						<div className="nav__inner__logo">
							<NavLink aria-label="Curate Mobile logo. Internal link to home page." onKeyDown={handleLinkKeyDown} to="/"><img alt="Curate Mobile logo." src={Logo} /></NavLink>
						</div>
						<div className="nav__inner__links">
							<ul>
								<li className="text--uppercase">
									<div className="btn-group">
										<button type="button" className="btn dropdown-toggle dropdown-toggle-solutions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											Solutions
										</button>
										<div className="dropdown-menu dropdown-menu-left" role="list">
											<Link aria-label="Overview. Internal link to solutions page." className="dropdown-item" onClick={this.handleSamePageLink} onKeyDown={handleLinkKeyDown} role="listitem" to="/Solutions">Overview</Link>
											<Link aria-label="App Marketing. Internal link to solutions page, automatically scrolled to the App Marketing section." className="dropdown-item" onClick={this.handleSamePageLink} onKeyDown={this.handleLinkSectionKeyDown} role="listitem" to="/Solutions#AppMarketing">App Marketing</Link>
											<Link aria-label="Social Performance. Internal link to solutions page, automatically scrolled to the Social Performance section." className="dropdown-item" onClick={this.handleSamePageLink} onKeyDown={this.handleLinkSectionKeyDown} role="listitem" to="/Solutions#SocialPerformance">Social Performance</Link>
											<Link aria-label="Fraud Blocking. Internal link to solutions page, automatically scrolled to the Fraud Blocking section." className="dropdown-item" onClick={this.handleSamePageLink} onKeyDown={this.handleLinkSectionKeyDown} role="listitem" to="/FraudBlocking">Fraud Blocking</Link>
											<Link aria-label="Partner Management Platform. Internal link to Partner management platform page." className="dropdown-item" onClick={this.handleSamePageLink} onKeyDown={this.handleLinkSectionKeyDown} role="listitem" to="/Partner-management-platform">Partner Management Platform</Link>
										</div>
									</div>
								</li>
								<li className="text--uppercase"><NavLink aria-label="About Us. Internal link to company page." onKeyDown={handleLinkKeyDown} to="/Company">About Us</NavLink></li>
								<li className="text--uppercase"><a aria-label="Insights. External link to Curate's blog." href="https://insights.curatemobile.com">Insights</a></li>
								<li className="text--uppercase">
									<div className="btn-group">
										<button type="button" className="btn btn--outlined dropdown-toggle dropdown-toggle-products " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											Login
										</button>
										<div className="dropdown-menu dropdown-menu-right" role="list">
											<a aria-label="I'm an advertiser. Exterinal link to Curate Mobile Console advertiser login page." className="dropdown-item" onKeyDown={handleLinkKeyDown} role="listitem" href="https://console.curatemobile.com/advertiser/login">I'm an advertiser</a>
											<a aria-label="I'm a publisher. External link to Curate Mobile Console publisher login page" className="dropdown-item" onKeyDown={this.handleLinkSectionKeyDown} role="listitem" href="https://console.curatemobile.com/publisher/login">I'm a publisher</a>
											<a aria-label="Need a Publisher Account? Sign-up. External link Curate Mobile Console publisher register page" className="dropdown-item small-line-height" onKeyDown={this.handleLinkSectionKeyDown} role="listitem" href="https://console.curatemobile.com/publisher/register">Need a Publisher <br />Account? Sign-up</a>
										</div>
									</div>
								</li>
								<li className="text--uppercase">
									<div className="btn-group">
										<Link aria-label="Contact. Internal link to contact page."  role="listitem" to="/Contact">
											<button type="button" className="btn btn--blue" style={{marginLeft:'15px'}}>
												Contact
											</button>
										</Link>
									</div>
								</li>
							</ul>
						</div>
						<div aria-expanded="false" aria-label="Button that toggles navigation display." className="nav__inner__hamburger" id="nav-flyout-logo" onClick={this.toggleMobileNavigation} onKeyPress={this.toggleMobileNavigation} role="button" tabIndex="0">
							<img alt="Hamburger menu icon." src={Hamburger} />
						</div>

						{/* Mobile side bar */}
						<div className={`nav__inner__flyout ${this.state.mobileFlyoutOpen ? "nav__inner__flyout__open" : "nav__inner__flyout__closed"}`}>
							<div className="nav__inner__flyout__header">
								<NavLink aria-label="Curate Mobile logo. Internal link to home page." onClick={this.toggleMobileNavigation} to="/"><img alt="Curate Mobile logo." src={Logo} /></NavLink>
								<div aria-expanded="true" aria-label="Button that toggles navigation display." id="nav-flyout-links" onKeyPress={this.toggleMobileNavigation} role="button" tabIndex="0">
									<img alt="Close menu, x-shaped icon." onClick={this.toggleMobileNavigation} src={XIcon} />
								</div>
							</div>
							<div className="nav__inner__flyout__links">
								<ul className={`${this.state.mobileSolutionsSubFlyoutOpen || this.state.mobileLoginSubFlyoutOpen || this.state.mobileTechStackFlyoutOpen ? "nav__inner__flyout__links-closed" : "nav__inner__flyout__links-open"}`}>
									<li className="text--uppercase nav__inner__flyout__login-link" onKeyDown={this.lockTab}>
										<div aria-label="Solutions. Internal link Solutions sub-menu" onClick={this.toggleMobileSolutionsNavigation} onKeyDown={handleLinkKeyDown}><span>Solutions</span><Icon>chevron_right</Icon></div>
									</li>
									<li className="text--uppercase nav__inner__flyout__login-link">
										<div aria-label="Teck Stack. Internal link Tech Stack sub-menu" onClick={this.toggleMobileTeckStackNavigation} onKeyDown={handleLinkKeyDown}><span>Tech Stack</span><Icon>chevron_right</Icon></div>
									</li>
									<li className="text--uppercase"><NavLink aria-label="About Us. Internal link to company page." onClick={this.closeMobileNavigation} onKeyDown={handleLinkKeyDown} to="/Company">About Us</NavLink></li>
									<li className="text--uppercase"><a aria-label="Insights. External link to Curate's blog." href="https://insights.curatemobile.com">Insights</a></li>
									<li className="text--uppercase" onKeyDown={this.lockTab}><NavLink aria-label="Contact. Internal link to contact page." onClick={this.closeMobileNavigation} onKeyDown={handleLinkKeyDown} to="/Contact">Contact</NavLink></li>
									<li className="text--uppercase nav__inner__flyout__login-link text--white" onKeyDown={this.lockTab}>
										<div aria-label="Login. Internal link login sub-menu" onClick={this.toggleMobileLoginNavigation} onKeyDown={handleLinkKeyDown}><span>Login</span><Icon>chevron_right</Icon></div>
									</li>
								</ul>

								{/* Solutions submenu */}
								<ul className={`nav__inner__flyout__links-sub ${this.state.mobileSolutionsSubFlyoutOpen ? "nav__inner__flyout__links-open" : "nav__inner__flyout__links-closed"}`}>
									<li className="text--uppercase nav__inner__flyout__login-link submenu" onKeyDown={this.lockTab}>
										<div aria-label="Solutions sub-menu with left chevron. Internal link back to main menu." onKeyDown={handleLinkKeyDown} onClick={this.toggleMobileSolutionsNavigation}><Icon>chevron_left</Icon><span>Solutions</span></div>
									</li>
									<li className="text--uppercase" onKeyDown={this.lockTab}>
										<Link aria-label="Overview. Internal link to Solutions page." onClick={this.closeMobileNavigation} onKeyDown={handleLinkKeyDown} role="listitem" to="/Solutions">Overview</Link>
									</li>
									<li className="text--uppercase" onKeyDown={this.lockTab}>
										<Link aria-label="Fraud Blocking. Internal link to Fraud Blocking page." onClick={this.closeMobileNavigation} onKeyDown={handleLinkKeyDown} role="listitem" to="/FraudBlocking">Fraud Blocking</Link>
									</li>
									<li className="text--uppercase" onKeyDown={this.lockTab}>
										<Link aria-label="SKAdNetwork. Internal link to SKAdNetwork page." onClick={this.closeMobileNavigation} onKeyDown={this.handleLinkSectionKeyDown} role="listitem" to="/SKAdNetwork">SKAdNetwork</Link>
									</li>
									<li className="text--uppercase" onKeyDown={this.lockTab}>
										<Link aria-label="Partner Management Platform. Internal link to Partner Management Platform page." onClick={this.closeMobileNavigation} onKeyDown={this.handleLinkSectionKeyDown} role="listitem" to="/Partner-management-platform">Partner Management Platform</Link>
									</li>
								</ul>

								{/* Tech Stack submenu */}
								<ul className={`nav__inner__flyout__links-sub ${this.state.mobileTechStackFlyoutOpen ? "nav__inner__flyout__links-open" : "nav__inner__flyout__links-closed"}`}>
									<li className="text--uppercase nav__inner__flyout__login-link submenu" onKeyDown={this.lockTab}>
										<div aria-label="Solutions sub-menu with left chevron. Internal link back to main menu." onKeyDown={handleLinkKeyDown} onClick={this.toggleMobileTeckStackNavigation}><Icon>chevron_left</Icon><span>Tech Stack</span></div>
									</li>
									<li className="text--uppercase" onKeyDown={this.lockTab}>
										<Link aria-label="Overview. Internal link to Solutions page." onClick={this.closeMobileNavigation} onKeyDown={handleLinkKeyDown} role="listitem" to="/TechStack">Overview</Link>
									</li>
									<li className="text--uppercase" onKeyDown={this.lockTab}>
										<Link aria-label="SKAdNetwork-as-a-Service. Internal link to SKAdNetwork-as-a-Service page." onClick={this.closeMobileNavigation} onKeyDown={this.handleLinkSectionKeyDown} role="listitem" to="/SKAdNetwork-as-a-Service">SKAdNetwork-as-a-Service</Link>
									</li>
								</ul>

								{/* Login submenu */}
								<ul className={`nav__inner__flyout__links-sub ${this.state.mobileLoginSubFlyoutOpen ? "nav__inner__flyout__links-open" : "nav__inner__flyout__links-closed"}`}>
									<li className="text--uppercase nav__inner__flyout__login-link submenu" onKeyDown={this.lockTab}>
										<div aria-label="Login sub-menu with left chevron. Internal link back to main menu." onKeyDown={handleLinkKeyDown} onClick={this.toggleMobileLoginNavigation}><Icon>chevron_left</Icon><span>Login</span></div>
									</li>
									<li className="text--uppercase" onKeyDown={this.lockTab}>
										<a aria-label="I'm an advertiser. Exterinal link to Curate Mobile Console advertiser login page." onKeyDown={handleLinkKeyDown} role="listitem" href="https://console.curatemobile.com/advertiser/login">I'm an advertiser</a>
									</li>
									<li className="text--uppercase" onKeyDown={this.lockTab}>
										<a aria-label="I'm a publisher. External link to Curate Mobile Console publisher login page" onKeyDown={this.handleLinkSectionKeyDown} role="listitem" href="https://console.curatemobile.com/publisher/login">I'm a publisher</a>
									</li>
									<li className="text--uppercase" onKeyDown={this.lockTab}>
										<a aria-label="Need a Publisher Account? Sign-up. External link Curate Mobile Console publisher register page" onKeyDown={this.handleLinkSectionKeyDown} role="listitem" href="https://console.curatemobile.com/publisher/register">Need a Publisher <br />Account? Sign-up</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
        	<Notification />
				</nav>

				<div aria-label="Main content below." id="main-content" onBlur={() => { document.getElementById('main-content').style.visibility = 'hidden'; }} role="dialog" tabIndex="-1">Main content below.</div>

			</div>
		);
	}
}

export default NavBar;
