// Dependencies
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// Local files
import { doesUserAllowAnimation, setupAnimationObserver, removeAnimation, animateElement } from "../helpers";

import HeroIcon from "../Images/skadnetwork-service/icon-skadservice-hero.png";
import CurateLogo from "../Images/skadnetwork-service/logo-curate.png";
import iconTarget from "../Images/products/icon-dsp-target.png";
import iconAttribution from "../Images/products/icon-dsp-attribution.png";
import iconReporting from "../Images/products/icon-dsp-reporting.png";
import iconBidder from "../Images/products/icon-dsp-bidder.png";

// Style
import "../Styles/SKAdService.css";

// Components
import CookiesBanner from "../Components/CookiesBanner";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";

class SKAdService extends Component {
  componentDidMount() {
    if (doesUserAllowAnimation()) {
      setupAnimationObserver(document.querySelectorAll('section'), 'animate__fade-in', 'fadeIn', 0.8);
      setupAnimationObserver(document.querySelectorAll('.benefits__title'), 'animate__fade-in', 'fadeIn', 0.8);
      setupAnimationObserver(document.querySelectorAll('.benefits__icons'), 'animate__fade-in', 'fadeIn', 0.8);

      (function () {
				const animationOptions = {
					root: null,
					rootMargin: '0px',
					threshold: 0.5
				};

        if (window.innerWidth <= 599) {
          for (let i = 1; i <= 4; i++) {
            const mobileElements = document.querySelector(`.animate__fade-in-up-${i}`)
            mobileElements.classList.remove(`animate__fade-in-up-${i}`);
          }
          document.querySelector('.benefits-button.animate__fade-in').classList.remove('animate__fade-in')
        } 
        
				let observerFadeInUp = new IntersectionObserver((element) => {
					const fadeDelay = 350;
					if (element[0].intersectionRatio > 0.4) {
						if (window.innerWidth > 599 && window.innerWidth <= 799) {
              animateElement(element[0].target.querySelector('.animate__fade-in-up-1'), 'fadeInUp');
              window.setTimeout(() => {
                animateElement(element[0].target.querySelector('.animate__fade-in-up-2'), 'fadeInUp');
              }, fadeDelay);
							window.setTimeout(() => {
                animateElement(element[0].target.querySelector('.animate__fade-in-up-3'), 'fadeInUp');
              }, fadeDelay * 1.5);
              window.setTimeout(() => {
                animateElement(element[0].target.querySelector('.animate__fade-in-up-4'), 'fadeInUp');
              }, fadeDelay * 2);
						} else if (window.innerWidth > 800){
							animateElement(element[0].target.querySelector('.animate__fade-in-up-1'), 'fadeInUp');
              animateElement(element[0].target.querySelector('.animate__fade-in-up-2'), 'fadeInUp');
              window.setTimeout(() => {
                animateElement(element[0].target.querySelector('.animate__fade-in-up-3'), 'fadeInUp');
                animateElement(element[0].target.querySelector('.animate__fade-in-up-4'), 'fadeInUp');
              }, fadeDelay);
						}
					}
				}, animationOptions);

				observerFadeInUp.observe(document.querySelector('.benefits__icons'));
			})();

    } else {
      removeAnimation(
        document.querySelectorAll(".animate__fade-in"),
        "animate__fade-in"
      );
    }
  }

  render() {
    return (
      <div id="skad-page">
        <CookiesBanner />
        <ScrollToTopOnMount />

        <section
          aria-label="SKAdNetwork-as-a-Service"
          className="skadservice-hero"
        >
          <div className="hero__img-container">
            <img src={HeroIcon} alt="Icon of a mobile device" />
          </div>
          <div className="hero__text">
            <h1 aria-hidden="false" tabIndex="-1" className="animate__fade-in">
              <span className="text-subtitle">NEW!</span>
              <span className="font--bold text-title">
                Introducing SKAdNetwork-as-a-Service
              </span>
            </h1>
            <p aria-hidden="false" tabIndex="-1" className="animate__fade-in">
              As a better way to better understand the industry’s evolution and
              how we are uniquely positioned to capitalize on it, we are not
              only offering Free SKAdNetwork verification and testing through
              our Swarm DSP; we are also introducing SKAdNetwork-as-a-Service.
              This means that{" "}
              <span className="highlight">you can use our SKAN ID</span>,{" "}
              <span className="highlight">or bring your own!</span> Curate will
              host your SKAN ID, do the securing, signing, and get you plisted.
            </p>
          </div>
        </section>

        <section
          aria-label="Curate's SKAdNetwork solutions"
          className="skadservice-intro"
        >
          <h4 aria-hidden="false" tabIndex="-1" className="animate__fade-in">
            We are one of only a handful of global solutions providers to
            receive SKAdNetwork certification on all MMP’s.
          </h4>
        </section>

        <section
          aria-label="The benefits of joining Curate"
          className="skadservice-benefits"
        >
          <div className="benefits__title">
            <h3 aria-hidden="false" tabIndex="-1" className="animate__fade-in">The benefits of joining us will become immediately clear:</h3>
          </div>
          <div className="benefits__icons">
            <div aria-hidden="false" className="benefit animate__fade-in-up-1">
              <div className="img-container">
                <img
                  src={iconTarget}
                  alt="Icon of an arrow hitting a target."
                />
              </div>
              <p>
              You will experience our transparency through expansive targeting criteria including over 20 factors like SKAdNetwork, Ad platform, audience, device ID, location and more.
              </p>
            </div>
            <div aria-hidden="false" className="benefit animate__fade-in-up-2">
              <div className="img-container">
                <img
                  src={iconBidder}
                  alt="Icon of a mobile phone displaying an advertisement."
                />
              </div>
              <p>
                We’re a mobile-first company, evident in our own proprietary
                products built from the ground-up focused on accuracy and
                scalability.
              </p>
            </div>
            <div aria-hidden="false" className="benefit animate__fade-in-up-3">
              <div className="img-container">
                <img src={iconReporting} alt="Icon of a bar graph." />
              </div>
              <p>
                Our model is driven by data and KPI. We bring an ROAS-centric
                mindset to all that we do, and pass that onto our partners.
              </p>
            </div>
            <div aria-hidden="false" className="benefit animate__fade-in-up-4">
              <div className="img-container">
                <img
                  src={iconAttribution}
                  alt="Icon of a hand holding a credit card."
                />
              </div>
              <p>
                Our custom attribution and custom fraud-blocking capabilities
                allow you to set rules, attribution windows, caps, and CR rules
                to name a few of our capabilities. On average, this saves our
                clients over 22% off of what their MMP’s usually show as
                billable. We block undesirable events, before they hit your MMP.
              </p>
            </div>
          </div>
          <Link
            aria-label="Contact us. Internal link to contact page."
            className="benefits-button animate__fade-in"
            to="/contact"
          >
            Get Started Today!
          </Link>
        </section>

        <section
          aria-label="The benefits of joining Curate"
          className="skadservice-vision"
        >
          <img src={CurateLogo} alt="Curate Logo" />
          <p aria-hidden="false">
            Empowering every mobile marketer to succeed with confidence in a
            mobile consumer economy.
          </p>
        </section>
        <Helmet title="SKAdNetwork-as-a-Service" />
      </div>
    );
  }
}

export default SKAdService;
