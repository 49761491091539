// Dependencies
import React, { Component } from 'react';
import Helmet from 'react-helmet';
// Local files
import { doesUserAllowAnimation, setupAnimationObserver, animateElement, removeAnimation } from '../helpers';

// Style
import '../Styles/Landing.css';

// Images
import imageCroppedFace from '../Images/landing/stock-identity-background-mobile-v3.png';
import logoCertifications from '../Images/landing/logos-certifications.png';
import graphicMobileAds from '../Images/landing/graphic-creative-background-mobile-v4.jpg';
import iconCollection from '../Images/landing/clients-logo-collection-desk.png';
import iconCollectionMobile from '../Images/landing/clients-logo-collection-mobile.png';

// Videos
import heroVideo from '../Videos/landing/stock-hero-v3.mp4';

// Components
import CookiesBanner from "../Components/CookiesBanner";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";
import WorkTogether from "../Components/WorkTogether";
import Awards from "../Components/Awards";

class Landing extends Component {
	componentDidMount() {
		// animation for landing-perform-confidence icons
		(function () {
			const animationOptions = {
				root: null,
				rootMargin: '0px',
				threshold: 0.9
			};

			let observerFadeInUp = new IntersectionObserver((element) => {
				const fadeDelay = 500;
				if (element[0].intersectionRatio > 0.8) {
					animateElement(element[0].target.querySelector('.animate__fade-in__delay-1'), 'fadeIn');
					animateElement(element[0].target.querySelector('.animate__fade-in__delay-2'), 'fadeIn');
					window.setTimeout(() => {
						animateElement(element[0].target.querySelector('.animate__fade-in__delay-3'), 'fadeIn');
					}, fadeDelay);
				}
			}, animationOptions);

			observerFadeInUp.observe(document.querySelector('.landing-perform-confidence'));
		})();

		if (doesUserAllowAnimation()) {
			setupAnimationObserver(document.querySelectorAll('section'), 'animate__fade-in', 'fadeIn', 0.8);
		} else {
			removeAnimation(document.querySelectorAll('.animate__fade-in'), 'animate__fade-in');
			for (let i = 1; i <= 3; i++) {
				removeAnimation(document.querySelectorAll(`.animate__fade-in__delay-${i}`), `animate__fade-in__delay-${i}`);
			}
		}
	}

	videoControlOnClick = () => {
		const videoElement = document.querySelector('.landing-video-hero__video');
		return videoElement.paused ? videoElement.play() : videoElement.pause();
	};

	render() {
		return (
			<div className='landing-page'>
				<CookiesBanner />

				<ScrollToTopOnMount />

				<section aria-label="Background video of several scenes of people interacting from their mobile devices." className="landing-video-hero">
					<video aria-label="Video element controls: To pause the video, use the enter key or mouse click. Video description: A compilation video showing several scenes of people interacting with their mobile devices. There is no audio, the video plays on an endless loop, and there are seven scenes in total. The first scene is a close up of a man in a street alley wearing headphones, and a big smile on his face. He is looking down at his mobile device. The second scene is a shot of a woman in a coffee shop. She is holding and looking at her mobile device in her right hand. In her left hand, she is holding a small coffee cup and slowly takes a sip without looking away from her mobile device. The third scene is a top-down shot of someone taking a photo of their food using their mobile device. The fourth scene is a shot of a woman laying down on a couch while reading her mobile device. She is smiling and performs a swipe action on her device. The fifth scene is of a child in a dark room laying on their stomach interacting with their mobile device. The light from the device is reflecting off the child's face. The sixth scene is of a person walking through a windowed-hallway at night in a city, scrolling through the content on their mobile device. The final scene is a shot of a concert from the view of someone in the crowd. The show is showing someone holding up their mobile device to record the concert." autoPlay className="landing-video-hero__video" loop onClick={this.videoControlOnClick} muted playsInline>
						<source src={heroVideo} type="video/mp4" />
						Our apologies, your browser appears to not support video.
					</video>
					<h1 aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Data-driven, mobile performance solutions for advertisers and agencies, providing scalable user acquisition, with outcomes-based scalable results and guaranteed ROAS</h1>
					<div className="divider__horizontal landing-video-hero__divider"></div>
				</section>

				<section aria-label="Our Clients" className="landing-clients">
					<h2 aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Our <span className="font--bold">Clients</span></h2>
					<p aria-hidden="false" className="animate__fade-in landing-clients__text" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Curate's solutions have aided companies large and small achieve aggressive growth metrics. From start-ups to Fortune 500s, we have lent our expertise to organizations in dozens of verticals and across multiple geo's.</p>
					<p aria-hidden="false" className="animate__fade-in landing-clients__text" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Curate is proud to work with the following brands on their mobile user acquisition needs:</p>
					<div className=" landing-clients__images animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))}>
						<img alt="" className="landing-clients__img--desk" src={iconCollection} />
						<img alt="" className="landing-clients__img--mobile" src={iconCollectionMobile} />
					</div>
				</section>

				<section aria-label="A solution for every mobile marketer & buyer." className="landing-mobile-channel">
					<h2 aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1"><span className="font--bold">We Deliver Customers, not just Impressions.</span></h2>
					<p aria-hidden="false" className="animate__fade-in landing-mobile-channel__subheader" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Data and ROAS-Centric; KPI Driven; Full Transparency, Control &amp; Automation</p>
				</section>

				<section aria-label="Background image of a person with the area around their eyes cropped out of the image." className="landing-identify-consumer">
					<div className="landing-identify-consumer__inner">
						<div className="landing-identify-consumer__inner__content">
							<div className="landing-identify-consumer__inner__content__divider"></div>
							<div className="landing-identify-consumer__inner__content__right">
								<h2 aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Introducing our Mobile-First,<br /><span className="font--bold">Data-Centric</span> Approach...</h2>
								<p aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Times are changing, and so is digital advertising. Worldwide mobile usage is at an all-time high. Mobile ad spending increased 600% in the last five years. The challenge for advertisers is to reach customers effectively, efficiently, at scale, and in the smartest way. Consumer dynamics skew heavily toward the mobile platform, and for advertisers toward performance-based as a way to drive quantifiable ROI during turbulent times. You need a partner with our experience and who is with you every step of the way.</p>
								<img alt="Person with the area around their eyes cropped out." src={imageCroppedFace} />
							</div>
						</div>
					</div>
				</section>

				<section aria-label="Targeting solutions in a cookie-less world." className="landing-privacy-cookieless">
					<h2 aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Our On-Demand, <span className="font--bold">Cloud-Based</span> Performance Platform</h2>
					<p aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Allows advertisers maximum control of their campaigns through a custom advertiser console, custom reporting, tracking and insight directly into publishers’ performance.</p>
				</section>

				<section aria-label="Background image of people using laptops, tablets, and mobile phones." className="landing-perform-confidence">
					<h2 aria-hidden="false" className="animate__fade-in__delay-1" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Our <span className="font--bold">Fully-Automated</span>, Proprietary DSP</h2>
					<p aria-hidden="false" className="animate__fade-in__delay-2" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">SWARM is a ROAS-centric programmatic DSP offering full-service and self-service options. It is a fully-automated platform that features powerful ROAS and KPI-driven algorithms as well as proficient AI and machine-based learning capabilities.</p>
					<img alt="Group of five certification logos. Official member CAAF - Coalition against ad fraud, GDPR compliant, DoubleVerify, FraudScore, and IAS - Integral Ad Science." aria-hidden="false" className="animate__fade-in__delay-3" onFocus={((event) => animateElement(event.target, 'fadeIn'))} src={logoCertifications} tabIndex="-1" />
				</section>

				<section aria-label="Background image of isometric mobile phones displaying various creative ads." className="landing-creative">
					<div className="landing-creative__inner">
						<h2 aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1"><span className="font--bold">Anti-Fraud</span> Technology</h2>
						<div className="divider__horizontal landing-creative__inner__divider"></div>
						<p aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">We know trust and transparency are crucial to any partnership. That’s why our proprietary anti-fraud monitoring and alert system underscores everything we do, and provides assurance for every campaign we run.</p>
						<img alt="Isometric mobile phones displaying various creative ads." src={graphicMobileAds} />
					</div>
				</section>

				<WorkTogether />

				<Awards />

				<Helmet title='Home' />
			</div>
		);
	}
}

export default Landing;
