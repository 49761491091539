// Dependencies
import React, { Component } from 'react';
import Slider from 'react-slick';
// Local files
import { doesUserAllowAnimation, setupAnimationObserver, animateElement, removeAnimation } from '../helpers';

// Images
import graphicAwardForbes from '../Images/components/awards-graphic-forbes-technology-council-member-2021.png';
import graphicAwardGlobe from '../Images/components/awards-graphic-globe.png';
import graphicAwardMia from '../Images/components/awards-graphic-mia.png';
import graphicAwardStartup50 from '../Images/components/awards-graphic-startups50.png';
import graphicAwardDeloitte from '../Images/components/awards-graphic-deloitte.png';
import graphicAwardErnstYoung from '../Images/components/awards-graphic-ernst-young.png';
import graphicAwardDrum from '../Images/components/awards-graphic-drum.png';
import graphicAwardStevie from '../Images/components/awards-graphic-stevie.png';
import graphicAwardFinancialTimes from '../Images/components/awards-graphic-financial-times-2020.png';
import graphicAwardCouncilOfInnovators from '../Images/components/awards-graphic-council-canadian-innovators.png';

const awardSliderSettings = {
	accessibility: true,
	arrows: false,
	autoplay: true,
	dots: false,
	draggable: false,
	infinite: true,
	pauseOnFocus: true,
	pauseOnHover: true,
	slidesToScroll: 1,
	slidesToShow: 4,
	responsive: [
		{
			breakpoint: 768,
			settings: {
				arrows: false,
				autoplay: true,
				dots: false,
				draggable: false,
				infinite: true,
				pauseOnFocus: true,
				pauseOnHover: true,
				slidesToScroll: 1,
				slidesToShow: 3
			}
		},
		{
			breakpoint: 550,
			settings: {
				arrows: false,
				autoplay: true,
				dots: false,
				draggable: false,
				infinite: true,
				pauseOnFocus: true,
				pauseOnHover: true,
				slidesToScroll: 1,
				slidesToShow: 1
			}
		}
	]
};

class Awards extends Component {
	constructor(props) {
		super(props);
		this.pause = this.pause.bind(this);
		this.play = this.play.bind(this);
	}

	componentDidMount() {
		if (doesUserAllowAnimation()) {
			setupAnimationObserver(document.querySelectorAll('section'), 'animate__fade-in', 'fadeIn', 0.5);
		} else {
			removeAnimation(document.querySelectorAll('.animate__fade-in'), 'animate__fade-in');
		}
	}

	pause(element) {
		this.slider.slickPause();
		if (element.style.opacity !== 1) {
			setupAnimationObserver(document.querySelectorAll('section'), 'animate__fade-in', 'fadeIn', 0.1);
		}
	}

	play() {
		this.slider.slickPlay();
	}

	render() {
		return (
			<section aria-label="Awards and recognition." className="component-awards">
				<h2 aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1"><span className="font--bold">Awards</span> & Recognition.</h2>
				<p aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">We’ve been recognized throughout the industry for our commitment to ethical practices and forward-thinking approach.</p>
				<div className="component-awards__images">
					<p aria-hidden={false} hidden={true}>The adjacent section is a carousel of award images that auto plays through the items. To pause the carousel, focus on any element of the carousel. To move the carousel to the next or previous item, use right or arrow keys. To navigate between carousel items, use the up and down arrow keys. To move To exit the carousel, use the tab key.</p>
					<Slider ref={c => (this.slider = c)} {...awardSliderSettings}>
						<div aria-hidden="false" className="animate__fade-in component-awards__images__slider-item" onBlur={this.play} onFocus={((event) => this.pause(event.target))} role="marquee" tabIndex="-1"><img alt="Award logo. 2020 Official Member - Forbes Technology Council" src={graphicAwardForbes} /></div>
						<div aria-hidden="false" className="animate__fade-in component-awards__images__slider-item" onBlur={this.play} onFocus={((event) => this.pause(event.target))} role="marquee" tabIndex="-1"><img alt="Award logo. The Globe and Mail's Canada's Top Growing Companies 2019. Placed number 33." src={graphicAwardGlobe} /></div>
						<div aria-hidden="false" className="animate__fade-in component-awards__images__slider-item" onBlur={this.play} onFocus={((event) => this.pause(event.target))} role="marquee" tabIndex="-1"><img alt="Award logo. Mia awards. Innovative ad partnership with Telus. 2016 award nominee." src={graphicAwardMia} /></div>
						<div aria-hidden="false" className="animate__fade-in component-awards__images__slider-item" onBlur={this.play} onFocus={((event) => this.pause(event.target))} role="marquee" tabIndex="-1"><img alt="Award logo. 2018 Startup 50. Canada's top new growth companies." src={graphicAwardStartup50} /></div>
						<div aria-hidden="false" className="animate__fade-in component-awards__images__slider-item" onBlur={this.play} onFocus={((event) => this.pause(event.target))} role="marquee" tabIndex="-1"><img alt="Award logo. Deloitte. Technology Fast50. Nominee 2018." src={graphicAwardDeloitte} /></div>
						<div aria-hidden="false" className="animate__fade-in component-awards__images__slider-item" onBlur={this.play} onFocus={((event) => this.pause(event.target))} role="marquee" tabIndex="-1"><img alt="Award logo. Ernst & Young. Entrepreneur of the year. 2018 award nominee." src={graphicAwardErnstYoung} /></div>
						<div aria-hidden="false" className="animate__fade-in component-awards__images__slider-item" onBlur={this.play} onFocus={((event) => this.pause(event.target))} role="marquee" tabIndex="-1"><img alt="Award logo. The Drum Marketing Awards USA 2019. Hennessey award winner. Partner with Havas Media." src={graphicAwardDrum} /></div>
						<div aria-hidden="false" className="animate__fade-in component-awards__images__slider-item" onBlur={this.play} onFocus={((event) => this.pause(event.target))} role="marquee" tabIndex="-1"><img alt="Award logo. 2018 Stevie Bronze Winner." src={graphicAwardStevie} /></div>
						{/* new */}
						<div aria-hidden="false" className="animate__fade-in component-awards__images__slider-item" onBlur={this.play} onFocus={((event) => this.pause(event.target))} role="marquee" tabIndex="-1"><a href="https://www.ft.com/americas-fastest-growing-companies-2020" rel="noopener noreferrer" role="listitem" target="_blank"><img alt="Award logo. The Americas' Fastest Growing Companies 2020." src={graphicAwardFinancialTimes} /></a></div>
						<div aria-hidden="false" className="animate__fade-in component-awards__images__slider-item" onBlur={this.play} onFocus={((event) => this.pause(event.target))} role="marquee" tabIndex="-1"><img alt="Award logo. Council of Canadian Innovators." src={graphicAwardCouncilOfInnovators} /></div>
					</Slider>
				</div>
			</section>
		);
	}
}

export default Awards;
