// Dependencies
import React, { Component } from 'react';
import Helmet from 'react-helmet';

// Style
import '../Styles/PrivacyPolicy.css';

// Components
import CookiesBanner from "../Components/CookiesBanner";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";

class PrivacyPolicy extends Component {
	constructor(props) {
		super(props);

		let cookieOptOut;

		if (typeof (Storage) !== 'undefined') {
			cookieOptOut = localStorage.getItem('cookieOptOut') || false;
		} else {
			cookieOptOut = false;
		}

		this.state = {
			cookieOptOut: cookieOptOut ? cookieOptOut : false
		};
	}

	handleOptIn = event => {
		if (typeof (Storage) !== 'undefined') {
			localStorage.setItem('cookieConsent', true);
			localStorage.setItem('cookieOptOut', false);
			sessionStorage.setItem('gaInit', false);

			window.location.reload();
		}
	}

	handleOptOut = event => {
		if (typeof (Storage) !== 'undefined') {
			localStorage.setItem('cookieConsent', false);
			localStorage.setItem('cookieOptOut', true);

			window.location.reload();
		}
	}

	render() {
		return (
			<div id="privacy-policy">
				<CookiesBanner />
				<ScrollToTopOnMount />

				<section aria-label="Background close up image of a person interacting with their laptop." className="privacy-hero">
					<h1><span className="font--bold">Our Privacy</span> Dashboard</h1>
					<div className="divider__horizontal privacy-hero__divider"></div>
				</section>

				<div aria-label="Privacy policy last updated date." className="privacy-hero__date">
					<p>Last modified: January 3rd, 2020</p>
				</div>

				<section aria-label="Privacy policy content." className="privacy-content">
					<div className="privacy-document__section">
						<h2><span className="font--bold">Our Commitment</span> to Your Privacy.</h2>
						<div className="divider__horizontal privacy-document__section__divider background--orange"></div>
						<p>Curate Mobile Ltd. (<span className="font--bold">"Company"</span> or <span className="font--bold">"We"</span>) respect your privacy and are committed to protecting it through our compliance with this policy. This policy and any other documents referred to in it describes the personal information that we collect from you, or that you provide on this website (our "Website") and how that information may be used or disclosed by us.</p>
						<p>This policy does not apply to information we collect through any other means, or to information collected on any other Company, [affiliate, or third-party site or by any third-party application that may link to or be accessible from the Website.</p>
						<p>Please read the following carefully to understand our policies and practices regarding your personal information and how we will treat it. By using or accessing our Website, you agree to this privacy policy. This policy may change from time to time and your continued use of the Website is deemed to be an acceptance of such changes, so please check the policy periodically for updates.</p>

						<h3>Children Under the Age of 13</h3>
						<p>Our Website is not intended for children under 13 years of age. No one under age 13 may provide any personal information to, or on, the Website. We do not knowingly collect personal information from children under 13. In the event that we learn that we have collected personal information from a child under age 13 without verification of parental consent, we will delete that information. If you believe that we might have any information from or about a child under 13, please contact us.</p>

						<h3>Information We Collect About You</h3>
						<p className="privacy-document__section__p--no-padding">We collect several types of information from and about users of our Website, including:</p>
						<ul>
							<li>Information you provide to us.</li>
							<li>Information we collect as you navigate through the site, including usage details, IP addresses, and cookies and web beacons.</li>
							<li>Information we receive from third parties, for example, our business partners.</li>
						</ul>

						<h3>Information You Provide to Us</h3>
						<p className="privacy-document__section__p--no-padding">We may collect and use the following information that you provide to us:</p>
						<ul>
							<li>Information that you provide by filling in forms on our Website. This includes information provided at the time of registering to use our Website or services, subscribing to our services, posting material or requesting further services. We may also ask you for information when you enter a contest or promotion sponsored by us, and when you report a problem with our Website.</li>
							<li>Records and copies of your correspondence (including e-mail addresses), if you contact us.</li>
							<li>Your responses to surveys that we might ask you to complete for research purposes, although you do not have to respond to them.</li>
							<li>Details of transactions you carry out through our Website and of the fulfilment of your orders.</li>
						</ul>
					</div>

					<div className="privacy-content__divider"></div>

					<div className="privacy-document__section">
						<h2>Usage Details, IP Addresses, Cookies & <span className="font--bold">Web Beacons.</span></h2>
						<div className="divider__horizontal privacy-document__section__divider background--red"></div>
						<ul>
							<li><span className="font--bold">Usage Details and IP Addresses.</span> As you navigate through the Website, we may also collect details of your visits to our Website, including, but not limited to, traffic data, location data, logs and other communication data, and the resources that you access, as well as information about your computer and internet connection, including your IP address, operating system and browser type, for system administration and to report aggregate information to our advertisers. This is statistical data about our users' equipment, browsing actions and patterns, and does not identify any individual.</li>
							<li>
								<span className="font--bold">Cookies and/or Pixel Tags.</span> For the same reason, we may obtain information about your general internet usage by using cookies. A cookie or pixel tag is a small file stored on the hard drive of your computer. Use of cookies and pixel tags help us to improve our Website and to deliver a better and more personalized service by enabling us to:
								<ul>
									<li>Estimate our audience size and usage patterns.</li>
									<li>Store information about your preferences, allowing us to customize our Website according to your individual interests.</li>
									<li>Speed up your searches.</li>
									<li>Recognize you when you return to our Website.</li>
								</ul>
							</li>
						</ul>
						<p>You may refuse to accept cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website.</p>
						<ul>
							<li><span className="font--bold">Web Beacons.</span> Pages of the Website may contain electronic images known as web beacons (also referred to as single-pixel gifs) that permit the Company to count users who have visited those pages and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity). Web beacons are not used to access your personal information on the Website and are only used to compile aggregated statistics concerning the use of the Website.</li>
							<li><span className="font--bold">Third-Party Advertiser Use of Cookies and Web Beacons.</span> Some advertisements on the Website are served by third-party advertising companies. Please note that our advertisers may also use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about our users. We do not have control over third-party advertisers' cookies or how they may be used. If you have any questions about an advertisement, you should contact the responsible advertiser directly.</li>
						</ul>
						<p>We do not collect personally identifiable information automatically, but we may tie this information to your previously submitted personally identifiable information.</p>
					</div>

					<div className="privacy-content__divider"></div>

					<div className="privacy-document__section">
						<h2>Uses Made of the <span className="font--bold">Information.</span></h2>
						<div className="divider__horizontal privacy-document__section__divider background--orange"></div>
						<p className="privacy-document__section__p--no-padding">We use information that we collect about you or that you provide to us, including any personal information, in the following ways:</p>
						<ul>
							<li>To present our Website and its contents in a suitable and effective manner for you and for your computer.</li>
							<li>To provide you with information, products or services that you request from us.</li>
							<li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us.</li>
							<li>To notify you about changes to our service.</li>
							<li>To allow you to participate in interactive features of our service, when you choose to do so.</li>
							<li>To send promotional communications, such as special offers or other promotions (if you have subscribed) and to display advertisements we believe may be relevant to you, including using the data we collect to develop or refine algorithms.</li>
							<li>To analyse the accuracy and effectiveness of our website and services.</li>
							<li>To understand our customers and their needs.</li>
							<li>To meet our obligations as required by law, such as fraud detection and enforcing contractual terms.</li>
						</ul>
						<p>We may also use your information, or permit selected third parties to use your information to contact you about goods and services that may be of interest to you where you have consented to be contacted for such purposes. If you do not want us to use your information in this way, or to provide your information to third parties for marketing purposes, please adjust your user preferences accordingly in your account profile or select the option in the check the box).] For more information, see <a aria-label="External link to Practical Law website. Selecting this will open a new window." href="https://content.next.westlaw.com/Browse/Home/PracticalLaw?__lrTS=20191001184521145&transitionType=Default&contextData=(sc.Default)&firstPage=true&bhcp=1&tabName=Practice%20Areas" rel="noopener noreferrer" target="_blank">Choices About How We Use and Disclose Your Information</a>.</p>
					</div>

					<div className="privacy-content__divider"></div>

					<div className="privacy-document__section">
						<h2>Disclosure of <span className="font--bold">Your Information.</span></h2>
						<div className="divider__horizontal privacy-document__section__divider background--red"></div>
						<p className="privacy-document__section__p--no-padding">We may disclose personal information that you provide via this Website to the following third parties:</p>
						<ul>
							<li>Our subsidiaries and affiliates, advertisers, partners, vendors, service providers and other trusted third parties that we have agreements which require a commitment to privacy compliant with this policy.</li>
							<li>Contractors and service providers we use to support our business.</li>
							<li>In the event of merger, acquisition, or any form of sale of some or all of the Company’s assets, in which case personal information held by the Company about its customers will be among the assets transferred to the buyer.</li>
						</ul>
						<p className="privacy-document__section__p--no-padding">We may also disclose your personal information to third parties to:</p>
						<ul>
							<li>Comply with any court order or other legal obligation.</li>
							<li>Enforce or apply our terms of use, terms of sale and other contractual agreements.</li>
							<li>Protect the rights, property, or safety of the Company, our customers, or others.</li>
						</ul>
					</div>

					<div className="privacy-content__divider"></div>

					<div className="privacy-document__section">
						<h2>Choices About How We Use & Disclose <span className="font--bold">Your Information.</span></h2>
						<div className="divider__horizontal privacy-document__section__divider background--orange"></div>
						<p className="privacy-document__section__p--no-padding">We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:</p>
						<ul>
							<li><span className="font--bold">Cookies.</span> If you do not wish us to collect cookies, you may set your browser to refuse cookies, or to alert you when cookies are being sent. If you do so, please note that some parts of this site may then be inaccessible or not function properly.</li>
							<li><span className="font--bold">Promotional Offers from the Company or Third Parties.</span> If you do not wish us to share your personal information with unaffiliated or non-agent third parties for promotional purposes, you can opt-out by selecting the available opt-out method. You can also always opt-out by logging into the Website and adjusting your user preferences in your account profile by checking or unchecking the relevant boxes or by sending us an e-mail stating your request to info@curatemobile.com.</li>
							<li><span className="font--bold">Personalized Advertising.</span> If you do not want us to use personal information that we gather to allow third parties to personalize advertisements we display to you, you can opt-out by selecting the available method.</li>
						</ul>
						<p>You should review this privacy policy carefully, because if you do not agree with our practices, your ultimate choice is not to use the Website. Remember, by using any part of the Website, you accept and agree to our privacy practices. If we update this privacy policy, your continued use of the Website (following the posting of the revised privacy policy) means that you accept and agree to the terms of the revised privacy policy.</p>
					</div>

					<div className="privacy-content__divider"></div>

					<div className="privacy-document__section">
						<h2>Accessing and Correcting <span className="font--bold">Your Personal Information.</span></h2>
						<div className="divider__horizontal privacy-document__section__divider background--red"></div>
						<p>You can review and update your personal information by logging into the Website and visiting your account profile page and making changes. You may also send us an email at privacy@curatemobile.com to request access to, correct or delete any personal information that you have provided to us. We cannot delete your personal information except by also deleting your user account. This option is also available according to California Civil Code Section § 1798.83 and similar options available in other U.S. states and countries, including Canada, the United Kingdom, and residents of the E.U. pursuant to the General Protection Regulations (GDPR).</p>
						<p>California Residents and Privacy Rights under the California Consumer Privacy Act. California residents are permitted to request and obtain from us a list of the third parties to whom we have disclosed their personal information (if any) for their direct marketing purposes in the prior calendar year, as well as the types of personal information disclosed to those parties. California residents can request this information by submitting an email to: privacy@curatemobile.com.</p>
					</div>

					<div className="privacy-content__divider"></div>

					<div className="privacy-document__section">
						<h2>Data <span className="font--bold">Security.</span></h2>
						<div className="divider__horizontal privacy-document__section__divider background--orange"></div>
						<p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration and disclosure. All information you provide to us is stored on our secure servers behind firewalls. Any personal information will be encrypted using SSL technology.</p>
						<p>Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk.</p>
					</div>

					<div className="privacy-content__divider"></div>

					<div className="privacy-document__section">
						<h2><span className="font--bold">Changes</span> to Our Privacy Policy.</h2>
						<div className="divider__horizontal privacy-document__section__divider background--red"></div>
						<p>It is our policy to post any changes we make to our privacy policy on this page [and the Website home page]. If we make material changes to this privacy policy, we will notify you [by e-mail [or through a notice on our home page]]. The date the privacy policy was last revised is identified at the top of the page.</p>
					</div>

					<div className="privacy-content__divider"></div>

					<div className="privacy-document__section">
						<h2><span className="font--bold">Contact</span> Information.</h2>
						<div className="divider__horizontal privacy-document__section__divider background--orange"></div>
						<p>To ask questions or comment about this privacy policy and our privacy practices, contact us at:</p>
						<div className="privacy-document__section__contact">
							<div>
								<p>
									<span className="font--bold">Address:</span><br />
									Curate Mobile Ltd. - Attention: Privacy Officer<br />
									150 King Street West, Suite 1503<br />
									Toronto, Ontario M5H 1J9
								</p>
							</div>
							<div>
								<p><span className="font--bold">Email:</span> info@curatemobile.com</p>
								<p><span className="font--bold">Phone:</span> 1 416 840 4619</p>
							</div>
						</div>
					</div>

					<div className="privacy-content__divider"></div>

					<div className="privacy-document__section">
						<h2><span className="font--bold">Cookie Opt-Out</span> on this Website.</h2>
						<div className="divider__horizontal privacy-document__section__divider background--orange"></div>
						<p>This website uses cookies to improve its functions. Press the button below if you wish to opt out of Curate's cookie mechanism for tracking and ad targeting purposes on this website. When you opt out, and opt-out cookie will be stored on your web browser. If you disable, block, or delete this cookie, your opt-choice will not be known.</p>
						<div className={JSON.parse(this.state.cookieOptOut) ? 'privacy-document__section__opted-out--hide' : 'privacy-document__section__opted-out'}>
							<button aria-label="Opt out button. Select this to opt out of cookies for this website." className="privacy-document__section__button" onClick={this.handleOptOut}>OPT OUT</button>
							<p>You are currently accepting cookies.</p>
						</div>
						<div className={JSON.parse(this.state.cookieOptOut) ? 'privacy-document__section__opted-out' : 'privacy-document__section__opted-out--hide'}>
							<button aria-label="Opt in button. Select this to accept the use of cookies for this website." className="privacy-document__section__button" onClick={this.handleOptIn}>OPT IN</button>
							<p>You are currently not accepting cookies.</p>
						</div>
					</div>

				</section>

				<Helmet title='Privacy Policy' />
			</div>
		);
	}
}

export default PrivacyPolicy;
