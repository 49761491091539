// Dependencies
import React, { Component } from 'react';
import Slider from 'react-slick';
// Local files
import { doesUserAllowAnimation, setupAnimationObserver, animateElement, removeAnimation } from '../helpers';

// Images
import graphicGear from '../Images/components/carousel-solutions-icon-addressing-gear.png';
import graphicHands from '../Images/components/carousel-solutions-icon-addressing-hands.png';
import graphicCloud from '../Images/components/carousel-solutions-icon-addressing-cloud.png';

const carouselSettings = {
	accessibility: true,
	arrows: false,
	autoplay: true,
	dots: false,
	draggable: false,
	infinite: true,
	pauseOnFocus: true,
	pauseOnHover: true,
	slidesToScroll: 1,
	slidesToShow: 1,
	responsive: [
		{
			breakpoint: 550,
			settings: {
				arrows: false,
				autoplay: true,
				dots: false,
				draggable: false,
				infinite: true,
				pauseOnFocus: true,
				pauseOnHover: true,
				slidesToScroll: 1,
				slidesToShow: 2
			}
		},
		{
			breakpoint: 350,
			settings: {
				arrows: false,
				autoplay: true,
				dots: false,
				draggable: false,
				infinite: true,
				pauseOnFocus: true,
				pauseOnHover: true,
				slidesToScroll: 1,
				slidesToShow: 1
			}
		}
	]
};

class CarouselSolutions extends Component {
	constructor(props) {
    	super(props);
    	this.pause = this.pause.bind(this);
		this.play = this.play.bind(this);
  	}

	componentDidMount() {
		if (doesUserAllowAnimation()) {
			setupAnimationObserver(document.querySelectorAll('section'), 'animate__fade-in', 'fadeIn', 0.8);
		} else {
			removeAnimation(document.querySelectorAll('.animate__fade-in'), 'animate__fade-in');
		}
	}

  	pause() {
  		this.slider.slickPause();
	}

	play() {
  		this.slider.slickPlay();
	}

	render() {
		return (
			<section aria-label="Addressing industry concerns." className="component-carousel-solutions">
				<div className="component-carousel-solutions__images">
					<p aria-hidden={false} hidden={true}>The adjacent section is a carousel of informational icons and text that auto plays through the items. To pause the carousel, focus on any element of the carousel. To move the carousel to the next or previous item, use right or arrow keys. To navigate between carousel items, use the up and down arrow keys. To move To exit the carousel, use the tab key.</p>
					<Slider ref={c => (this.slider = c)} {...carouselSettings}>
					<div aria-hidden="false" className="animate__fade-in component-carousel-solutions__images__slider-item" onBlur={this.play} onFocus={this.pause} role="marquee" tabIndex="-1">
						<img alt="Icon of gear with user silhouette in it." src={graphicGear} />
						<p  className="" onFocus={((event) => animateElement(event.target, 'fadeIn'))} >Government Regulations</p>
					</div>
					<div aria-hidden="false" className="animate__fade-in component-carousel-solutions__images__slider-item" onBlur={this.play} onFocus={this.pause} role="marquee" tabIndex="-1">
						<img alt="Icon of people's hands raised." src={graphicHands} />
						<p>Fraud Concerns</p>
					</div>
					<div aria-hidden="false" className="animate__fade-in component-carousel-solutions__images__slider-item" onBlur={this.play} onFocus={this.pause} role="marquee" tabIndex="-1">
						<img alt="Icon of a cloud and locked padlock." src={graphicCloud} />
						<p>Privacy Forward</p>
					</div>
					</Slider>
				</div>
			</section>
		);
	}
}

export default CarouselSolutions;
