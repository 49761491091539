// Dependencies
import React, { Component } from 'react';
import ReactGA from 'react-ga';
// Local files
import { handleLinkKeyDown } from '../helpers';

class CookiesBanner extends Component {
	constructor(props) {
		super(props);

		if (document.querySelector('script[src="https://www.google-analytics.com/analytics.js"]')) {
			sessionStorage.setItem('gaInit', true);
		} else {
			sessionStorage.setItem('gaInit', false);
		}

		let cookieConsent;
		let cookieOptOut;
		let gaInit;

		if (typeof(Storage) !== 'undefined') {
			cookieConsent = localStorage.getItem('cookieConsent') || false;
			cookieOptOut = localStorage.getItem('cookieOptOut') || false;
			gaInit = sessionStorage.getItem('gaInit') || false;
		} else {
			cookieConsent = false;
			cookieOptOut = false;
			gaInit = false;
		}

		this.state = {
			cookieConsent: cookieConsent ? cookieConsent : false,
			cookieOptOut: cookieOptOut ? cookieOptOut : false,
			gaInit: gaInit ? gaInit : false
		};

		if (JSON.parse(cookieConsent) && JSON.parse(cookieOptOut) === false && JSON.parse(gaInit) === false) {
			this.initializeGA();
		} else if (JSON.parse(cookieConsent) && JSON.parse(cookieOptOut) === false && JSON.parse(gaInit) === true) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}

	componentDidMount() {
		const cookieBannerElement = document.getElementById('cookies-banner-container');
		if (cookieBannerElement) {
			window.setTimeout(() => {
				cookieBannerElement.focus();
			}, 200);
		}
	}

	handleConsentClick = event => {
		if (typeof(Storage) !== 'undefined') {
			localStorage.setItem('cookieConsent', true);
			localStorage.setItem('cookieOptOut', false);
		}

		this.setState({ cookieConsent: true });

		if (JSON.parse(this.state.gaInit) === false) {
			this.initializeGA();
		}
  	}

	handleConsentKey = event => {
		if (event.key !== 'Enter') {
			console.log('ENTER');
			return;
		}

		if (typeof(Storage) !== 'undefined') {
			localStorage.setItem('cookieConsent', true);
			localStorage.setItem('cookieOptOut', false);
		}

		this.setState({ cookieConsent: true });

		if (JSON.parse(this.state.gaInit) === false) {
			this.initializeGA();
		}

		handleLinkKeyDown(event);
  	}

	initializeGA = () => {
		ReactGA.initialize('UA-186749239-1');
		ReactGA.pageview(window.location.pathname + window.location.search);

		sessionStorage.setItem('gaInit', true);
	}

	render() {
		const hasConsent = this.state.cookieConsent;
		const optedOut = this.state.cookieOptOut;

		if (hasConsent || optedOut) {
			return(null);
		} else {
			return (
				<div aria-label="This website uses cookies." className="cookies-banner" id="cookies-banner-container" role="dialog" tabIndex="0">
					<p>
						<span className="cookies-banner__header" id="cookie-banner-text" tabIndex="0">This website uses cookies.</span>
						<span className="cookies-banner__subheader" tabIndex="0">We are committed to trust and transparency and we want you to know what this means for you and your data.</span>
						<span className="cookies-banner__mobile_only_subheader" tabIndex="0"> Select the button to consent to the use of this technology.</span>
						<span className="cookies-banner__text" tabIndex="0"> Curate Mobile and our partners use technology such as cookies to improve your experience on our site and learn more about your preferences, ultimately to personalize content, ads and analyze our traffic. Select the button to consent to the use of this technology. For more information, please visit our privacy policy page.</span>
					</p>
					<button aria-label="I agree button. Select this to accept the use of cookies for this website." onClick={this.handleConsentClick} onKeyDown={this.handleConsentKey}>I Agree</button>
				</div>
			);
		}
	}
}

export default CookiesBanner;
