// Dependencies
import React, { Component } from 'react';
import Helmet from 'react-helmet';

// Local files
import { doesUserAllowAnimation, setupAnimationObserver, animateElements, animateElement, removeAnimation } from '../helpers';

// Style
import '../Styles/FraudBlock.css';

// Images
import iconCheckmark from '../Images/solutions/icon-solutions-checkmark.png';
import iconSetting from '../Images/solutions/icon-fraud-setting.png';
import iconBlacklist from '../Images/solutions/icon-fraud-blacklist.png';
import iconMonitor from '../Images/solutions/icon-fraud-monitoring.png';
import animationCustomRules from '../Images/fraudblock/animation-fraud-blocking.gif';

// Components
import CookiesBanner from "../Components/CookiesBanner";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";

const customRules = ["Private vs Public offer listing", "IFA Requirements", "OS version requirements", "Custom rules based on sources and supply partner", "Custom app black listing (Traffic Rules)", "CTTI (Click Time To Install) custom settings", "CR (Conversion Rates) custom settings", "Impressions required – Curate was the first in the performance industry to introduce this in 2016", "Secondary event requirements and custom payouts per event", "Custom alerts"];

const realTimeMonitorPrevention = ["Abnormal Device Parameters", "Fake Device IDs", "Multiple conversions from the same  IP subnet", "Monitoring User Agent Strings", "Proxy violations or anomalies usually associated with known botnets or adware (Looks like a mobile user from Germany when in fact they are on a desktop in China)", "Operating System Abnormal device distribution within traffic (device models, browser versions, operating system, etc."];

class FraudBlock extends Component {
  componentDidMount() {
    if (doesUserAllowAnimation()) {
      setupAnimationObserver(document.querySelectorAll('section'), 'animate__fade-in', 'fadeIn', 0.8);
      setupAnimationObserver(document.querySelectorAll('.fraudblock__header__text'), 'animate__fade-in', 'fadeIn', 0.8);
      setupAnimationObserver(document.querySelectorAll('.fraudblock__monitoring__list'), 'animate__fade-in', 'fadeIn', 0.8);
      setupAnimationObserver(document.querySelectorAll('.fraudblock__monitoring__heading'), 'animate__fade-in', 'fadeIn', 0.8);

      // animation for fraudblock__custom-rules__left content
      (function () {
        const animationOptions = {
          root: null,
          rootMargin: '0px',
          threshold: 0.5
        };

        let observerFadeInUp = new IntersectionObserver((element) => {
          if (element[0].intersectionRatio > 0.5) {
            if (window.innerWidth >= 881) {
              animateElements(element[0].target.querySelectorAll('.animate__fade-in-left'), 'fadeInLeft');
            }
          }
        }, animationOptions);

        observerFadeInUp.observe(document.querySelector('.fraudblock__custom-rules__left'));
      })();

      // animation for fraudblock__custom-rules__right content
      (function () {
        const animationOptions = {
          root: null,
          rootMargin: '0px',
          threshold: 0.5
        };

        let observerFadeInUp = new IntersectionObserver((element) => {
          if (element[0].intersectionRatio > 0.3) {
            if (window.innerWidth < 881) {
              animateElements(element[0].target.querySelectorAll('.animate__fade-in'), 'fadeIn');
            }
            if (window.innerWidth >= 881) {
              animateElements(element[0].target.querySelectorAll('.animate__fade-in-right'), 'fadeInRight');
            }
          }
        }, animationOptions);

        observerFadeInUp.observe(document.querySelector('.fraudblock__custom-rules__right'));
      })();

    } else {
      removeAnimation(document.querySelectorAll('.animate__fade-in'), 'animate__fade-in');
      removeAnimation(document.querySelectorAll('.animate__fade-in-left'), 'animate__fade-in-left');
      removeAnimation(document.querySelectorAll('.animate__fade-in-right'), 'animate__fade-in-right');
    }
  }

  render() {
    return (
      <div id="solutions-page">
        <CookiesBanner />
        <ScrollToTopOnMount />

        <section aria-label="Background image of friends socializing." className="fraudblock-hero">
          <h1 aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Solutions for <span className="font--bold">Fraud Blocking</span></h1>
          <p aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Fraud is one of the most frustrating components of digital advertising. Fraud costs advertisers billions of dollars each year, and it continues to be a problem. While there are many fraud measurement technologies available to the market, Curate offers our advertisers the <span className="font--bold">ability to set custom fraud blocking</span> and <span className="font--bold">attribution rules</span> directly through our proprietary tracking platform.</p>
        </section>

        <section className="fraudblock__header">
          <div className="fraudblock__header__text">
            <h2 aria-hidden="false" className="animate__fade-in font--bold" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">We offer advanced solutions</h2>
            <p aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Curate offers our clients the ability to <span className="font--bold">set custom fraud rules</span> on a campaign/source/placement level. We understand not all traffic is the same, which is why we provide the ability to set specific rules based on your desired traffic type, KPI’s, and down funnel activity. This allows you to decrease your eCPI and generate higher LTV users. Curate also maintains proprietary blacklists of sites and apps, to ensure brand safety.</p>
          </div>
          <div className="fraudblock__header__icons">
            <div aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">
              <img alt="Icon of a gear" src={iconSetting} />
              <p>Set custom fraud rules</p>
            </div>
            <div aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">
              <img alt="Icon of a hand, palm facing forward." src={iconBlacklist} />
              <p>Maintain proprietary blacklists</p>
            </div>
            <div aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">
              <img alt="Icon of a webpage with a magnifying glass" src={iconMonitor} />
              <p>Realtime monitoring</p>
            </div>
          </div>
        </section>

        <section className="fraudblock__custom-rules">
          <div className="fraudblock__custom-rules__left">
            <img alt="Animation of Curate Console showing custom settings" className="animate__fade-in animate__fade-in-left" onFocus={((event) => animateElement(event.target, 'fadeIn'))} src={animationCustomRules} />
          </div>
          <div className="fraudblock__custom-rules__right">
            <h3 aria-hidden="false" className="h3-title animate__fade-in animate__fade-in-right
          " onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Our Platform's Custom Rules</h3>
            <div className="divider__horizontal fraudblock__custom-rules__divider animate__fade-in animate__fade-in-right"></div>
            <ul className="animate__fade-in animate__fade-in-right">
              {customRules.map((rule, index) => (
                <li aria-hidden="false" key={index} tabIndex="-1"><p>{rule}</p></li>
              ))}
            </ul>
          </div>
        </section>

        <section className="fraudblock__tools">
          <p aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Curate works with leading fraud measurement tools such as <span className="font--bold">P360</span>, <span className="font--bold">FraudScore</span>, and <span className="font--bold">Datavisor</span>, to name a few. We are also able to create custom solutions or integrations to any proprietary fraud tools which you may be using.</p>
        </section>

        <section className="fraudblock__monitoring">
          <div className="fraudblock__monitoring__heading">
            <h2 aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Real-Time Monitoring</h2>
            <div className="divider__horizontal fraudblock__monitoring__divider animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))}></div>
          </div>

          <ul className="fraudblock__monitoring__list">
            <div className="fraudblock__monitoring__list__heading">
              <div>
                <img alt="Checkmark icon." className="blue-icon animate__fade-in" src={iconCheckmark} />
              </div>
              <h3>Our platform monitors in real time which aligns with the industries advanced monitoring</h3>
            </div>
            <li aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1"><p>Surface Level Fraud (No SDK implementation required) </p>
            </li>
            <li aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1"><p>Monitors traffic for patterns that look like bot traffic and/or emulators which mimic a users behavior and tricks tracking platforms into thinking the user is real.</p>
            </li>
          </ul>

          <ul className="fraudblock__monitoring__list">
            <div className="fraudblock__monitoring__list__heading">
              <div>
                <img alt="Checkmark icon." className="red-icon animate__fade-in" src={iconCheckmark} />
              </div>
              <h3>How to monitor against this:</h3>
            </div>
            {realTimeMonitorPrevention.map((method, index) => (
              <li aria-hidden="false" key={index} tabIndex="-1"><p>{method}</p></li>
            ))}
          </ul>

          <ul className="fraudblock__monitoring__list">
            <div className="fraudblock__monitoring__list__heading">
              <div>
                <img alt="Checkmark icon." className="orange-icon animate__fade-in" src={iconCheckmark} />
              </div>
              <h3>Other real-time monitoring our platform looks into:</h3>
            </div>
            <li><p className="fraudblock__monitoring__right__bottom animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))}><span className="font--bold">VPN patterns</span> – reviewing the % of installs that came from a VPN (Data Center, Proxy being used). Shouldn’t be more than 18% in the US and EU. This also depends of what part of the world the traffic is coming from. App installs should be evenly proportionate vs large percentage of installs coming from a small batch of placements. Based on our data, if there are 50 apps/sites passed back, a normal distribution of installs should be between 2% and 2.5%. There shouldn’t be one source that is dominant. That means there is some sort of cloaking going on. That would be defined as a source passing back an app or site name when in fact the source is most likely an incent path.</p></li>
          </ul>
        </section>

        <Helmet title='Fraud Blocking' />
      </div>
    )
  }
}

export default FraudBlock
