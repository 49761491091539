// Dependencies
import React, { Component } from 'react';
import { Link } from "react-router-dom";
// Local files
import { doesUserAllowAnimation, setupAnimationObserver, animateAndRemoveElement, animateElement, removeAnimation, handleLinkKeyDown } from '../helpers';

class WorkTogether extends Component {
	componentDidMount() {
		if (doesUserAllowAnimation()) {
			setupAnimationObserver(document.querySelectorAll('section'), 'animate__fade-in', 'fadeIn', 0.8);
			setupAnimationObserver(document.querySelectorAll('section'), 'animate__pulse', 'pulse', 0.8);
		} else {
			removeAnimation(document.querySelectorAll('.animate__fade-in'), 'animate__fade-in');
		}
	}

	animatePulse = (event) => {
		const targetElement = event.target.parentElement;
		targetElement.classList.add('animated', 'pulse');
		window.setTimeout(() => {
			targetElement.classList.remove('animated', 'pulse');
		}, 1000);
	}

	render() {
		return (
			<section aria-label="Work together." className="component-work-together">
				<h2 aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Let's Work Together.</h2>
				<div aria-hidden="false" className="animate__pulse" onFocus={((event) => animateAndRemoveElement(event.target.parentElement, 'pulse'))} onMouseEnter={((event) => animateAndRemoveElement(event.target.parentElement, 'pulse'))} tabIndex="-1">
					<Link aria-label="Contact us. Internal link to contact page." onKeyDown={handleLinkKeyDown} to="/contact">CONTACT US</Link>
				</div>
			</section>
		);
	}
}

export default WorkTogether;
