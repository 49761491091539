// Dependencies
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
// Local files
import { handleLinkKeyDown } from '../helpers';

// Components
import CookiesBanner from "../Components/CookiesBanner";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";

class SiteMap extends Component {
	render() {
		return (
			<div>
				<CookiesBanner />
				<ScrollToTopOnMount />

				<section aria-label="Site map listing links to all internal pages." className='site-map'>
					<h1><span className="font--bold">Site</span> map.</h1>
					<div className="site-map__divider"></div>
					<ul>
						<li><Link aria-label="Home. Internal link to home page." onKeyDown={handleLinkKeyDown} to="/">Home</Link></li>
						<li><Link aria-label="Solutions. Internal link to solutions page." onKeyDown={handleLinkKeyDown} to="/Solutions">Solutions</Link></li>
						<ul>
							<li><Link aria-label="Fraud Blocking. Internal link to fraud blocking page." onKeyDown={handleLinkKeyDown} to="/FraudBlocking">Fraud Blocking</Link></li>
						</ul>
						<li><Link aria-label="Products. Internal link to products page." onKeyDown={handleLinkKeyDown} to="/Products">Products</Link></li>
						<li><Link aria-label="Company. Internal link to company page." onKeyDown={handleLinkKeyDown} to="/Company">Company</Link></li>
						<li><Link aria-label="Contact. Internal link to contact page." onKeyDown={handleLinkKeyDown} to="/Contact">Contact</Link></li>
						<li>Login</li>
						<ul>
							<li><a aria-label="I'm an advertiser. External link to Curate Mobile Console advertiser login page." onKeyDown={handleLinkKeyDown} href="https://console.curatemobile.com/advertiser/login" rel="noopener noreferrer" target="_blank">I'm an advertiser</a></li>
							<li><a aria-label="External link to Curate Mobile Console publisher login page." onKeyDown={handleLinkKeyDown} href="https://console.curatemobile.com/publisher/login" rel="noopener noreferrer" target="_blank">I'm a publisher</a></li>
							<li><a aria-label="External link to Curate Mobile Console publisher sign-up page." onKeyDown={handleLinkKeyDown} href="https://console.curatemobile.com/publisher/register" rel="noopener noreferrer" target="_blank">Need a publisher account? Sign-up</a></li>
						</ul>
						<li><Link aria-label="Privacy Policy. Internal link to privacy policy page." onKeyDown={handleLinkKeyDown} to="/PrivacyPolicy">Privacy Policy</Link></li>
					</ul>
				</section>

				<Helmet title='Site Map' />
			</div>
		);
	}
}

export default SiteMap;
