// Dependencies
import React, { Component } from 'react';
import Slider from 'react-slick';
// Local files
import { doesUserAllowAnimation, setupAnimationObserver, removeAnimation } from '../helpers';

// Images
import iconLocation from '../Images/products/icon-dsp-location.png';
import iconMachine from '../Images/products/icon-dsp-machine.png';
import iconBidder from '../Images/products/icon-dsp-bidder.png';
import iconAudience from '../Images/products/icon-dsp-audience.png';
import iconTarget from '../Images/products/icon-dsp-target.png';
import iconAttribution from '../Images/products/icon-dsp-attribution.png';
import iconReporting from '../Images/products/icon-dsp-reporting.png';

const carouselSettings = {
	accessibility: true,
	arrows: false,
	autoplay: true,
	dots: false,
	draggable: false,
	infinite: true,
	pauseOnFocus: true,
	pauseOnHover: true,
	slidesToScroll: 1,
	slidesToShow: 1,
	responsive: [
		{
			breakpoint: 550,
			settings: {
				arrows: false,
				autoplay: true,
				dots: false,
				draggable: false,
				infinite: true,
				pauseOnFocus: true,
				pauseOnHover: true,
				slidesToScroll: 1,
				slidesToShow: 2
			}
		},
		{
			breakpoint: 350,
			settings: {
				arrows: false,
				autoplay: true,
				dots: false,
				draggable: false,
				infinite: true,
				pauseOnFocus: true,
				pauseOnHover: true,
				slidesToScroll: 1,
				slidesToShow: 1
			}
		}
	]
};

class CarouselProductsDSP extends Component {
	constructor(props) {
    	super(props);
    	this.pause = this.pause.bind(this);
		this.play = this.play.bind(this);
  	}

	componentDidMount() {
		if (doesUserAllowAnimation()) {
			setupAnimationObserver(document.querySelectorAll('section'), 'animate__fade-in', 'fadeIn', 0.8);
		} else {
			removeAnimation(document.querySelectorAll('.animate__fade-in'), 'animate__fade-in');
		}
	}

  	pause() {
  		this.slider.slickPause();
	}

	play() {
  		this.slider.slickPlay();
	}

	render() {
		return (
			<section aria-label="Addressing industry concerns." className="component-carousel-products-dsp">
				<div className="component-carousel-products-dsp__images">
					<p aria-hidden={false} hidden={true}>The adjacent section is a carousel of informational icons and text that auto plays through the items. To pause the carousel, focus on any element of the carousel. To move the carousel to the next or previous item, use right or arrow keys. To navigate between carousel items, use the up and down arrow keys. To move To exit the carousel, use the tab key.</p>
					<Slider ref={c => (this.slider = c)} {...carouselSettings}>
					<div aria-hidden="false" className="animate__fade-in component-carousel-products-dsp__images__slider-item" onBlur={this.play} onFocus={this.pause} role="marquee">
						<img alt='Icon of a map location indicator.' src={iconLocation} />
						<p>Location targeting<br />& insights</p>
					</div>
					<div aria-hidden="false" className="animate__fade-in component-carousel-products-dsp__images__slider-item" onBlur={this.play} onFocus={this.pause} role="marquee">
						<img alt='Icon of a gear.' src={iconMachine} />
						<p>Machine learning</p>
					</div>
					<div aria-hidden="false" className="animate__fade-in component-carousel-products-dsp__images__slider-item" onBlur={this.play} onFocus={this.pause} role="marquee">
						<img alt='Icon of a mobile phone displaying an advertisement.' src={iconBidder} />
						<p>Proprietary bidder</p>
					</div>
					<div aria-hidden="false" className="animate__fade-in component-carousel-products-dsp__images__slider-item" onBlur={this.play} onFocus={this.pause} role="marquee">
						<img alt='Icon of a mouse cursor.' src={iconAudience} />
						<p>Audience data &<br />optimization</p>
					</div>
					<div aria-hidden="false" className="animate__fade-in component-carousel-products-dsp__images__slider-item" onBlur={this.play} onFocus={this.pause} role="marquee">
						<img alt='Icon of an arrow hitting a target.' src={iconTarget} />
						<p>Targeting & scale</p>
					</div>
					<div aria-hidden="false" className="animate__fade-in component-carousel-products-dsp__images__slider-item" onBlur={this.play} onFocus={this.pause} role="marquee">
						<img alt='Icon of a hand holding a credit card.' src={iconAttribution} />
						<p>Measurement &<br />attribution</p>
					</div>
					<div aria-hidden="false" className="animate__fade-in component-carousel-products-dsp__images__slider-item" onBlur={this.play} onFocus={this.pause} role="marquee">
						<img alt='Icon of a bar graph.' src={iconReporting} />
						<p>Custom reporting</p>
					</div>
					</Slider>
				</div>
			</section>
		);
	}
}

export default CarouselProductsDSP;
