// Dependencies
import React, { Component } from 'react';

class Timeline extends Component {
	constructor(props) {
		super(props);

		this.state = { timelineMovingClick: true };

		this.pause = this.pause.bind(this);
		this.play = this.play.bind(this);
	}

	handleTimelineClick = () => {
		const updateState = (this.state.timelineMovingClick) ? false : true;
		this.setState({ timelineMovingClick: updateState });

		const timelineDiv = document.querySelector('.timeline-our-story__timeline');
		(updateState) ? timelineDiv.style.animationPlayState = 'running' : timelineDiv.style.animationPlayState = 'paused';
	}

	handleTimelineKeyDown = (event) => {
		// 13=enter
		if (event.keyCode === 13) {
			const updateState = (this.state.timelineMovingClick) ? false : true;
			this.setState({ timelineMovingClick: updateState });

			const timelineDiv = document.querySelector('.timeline-our-story__timeline');
			(updateState) ? timelineDiv.style.animationPlayState = 'running' : timelineDiv.style.animationPlayState = 'paused';
		}
	}

	pause() {
		document.querySelector('.timeline-our-story__timeline').style.animationPlayState = 'paused';
  		this.setState({ timelineMovingClick: false });
	}

	play() {
		document.querySelector('.timeline-our-story__timeline').style.animationPlayState = 'running';
  		this.setState({ timelineMovingClick: true });
	}

	render() {
		return (
			<section aria-label="Our story timeline." className="timeline-our-story">
				<h2><span className="font--bold">Our</span> Story.</h2>
				<div className="divider__horizontal timeline-our-story__divider"></div>
				<div aria-label="Panning image controls: To pause and play the panning effect, use the enter key or mouse click. image description: Timeline of Curate's history and milestones. In 2015, Curate was founded. In 2016, received first IRAP government funding, received MIA Awards 2016 nomination, and launched Swarm DSP. In 2017, launched proprietary console, became the first tag certified partner in Canada, and implemented ‘ads.txt’. In 2018, received second IRAP funding, launched Curate Alert, launched passbook capabilities, joined the Coalition Against Ad Fraud, received Deloitte Technology Fast50 nomination, received Stevie Bronze award, received Startup50 top growth company award, and acquired Juice Mobile. In 2019, launched advertiser login, launched supply partner Postback, launched IFA validation, launched supply fraud score, and joined the Canadian Council of Innovators. In 2020, joined the Forbes Technology council." className="timeline-our-story__timeline" onClick={this.handleTimelineClick} onKeyDown={this.handleTimelineKeyDown} onMouseEnter={this.pause} onMouseLeave={this.play} tabIndex="0"></div>
			</section>
		);
	}
}

export default Timeline;
