// Dependencies
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox, TextInput, RadioGroup, Modal, Button, Collapsible, CollapsibleItem, Icon } from 'react-materialize';
import { Helmet } from 'react-helmet';
import axios from 'axios';

// Local files
import { doesUserAllowAnimation, animateElements, removeAnimation, handleLinkKeyDown } from '../helpers';

// Style
import '../Styles/SKAd.css';

// Components
import CookiesBanner from '../Components/CookiesBanner';
import ScrollToTopOnMount from '../Components/ScrollToTopOnMount';

// Images
import allSkadNetworks from '../Images/skadnetwork/thumbnail-all-skadnetworks.png';
import skadAdvertisers from '../Images/skadnetwork/thumbnail-skad-advertisers.png';
import skadPublishers from '../Images/skadnetwork/thumbnail-skad-publishers.png';
import skadAdNetworks from '../Images/skadnetwork/thumbnail-skad-adnetworks.png';
import skadPosition from '../Images/skadnetwork/SKAd-swarm-position.png';

// Downloadable Files
import allSkadNetworksPDF from '../Images/skadnetwork/SKAd-all.pdf';
import skadAdvertisersPDF from '../Images/skadnetwork/SKAd-advertisers.pdf';
import skadPublishersPDF from '../Images/skadnetwork/SKAd-publishers.pdf';
import skadAdNetworksPDF from '../Images/skadnetwork/SKAd-ad-networks.pdf';
import swarmsUniquePositionPDF from '../Images/skadnetwork/SKAd-swarm-position.pdf'

const faqQuestionsAndAnswers = [
  {
    question: "How will conversion tracking work?",
    answer: <Fragment>
      <p>With SKAdNetwork, conversion tracking will now be done by Apple. There are 3 parties in SKAdNetwork, each with their own unique responsibilities:</p>
      <ul>
        <li>
          <p className="text--bold">The Ad Network</p>
          <p>The Ad Network is responsible for signing ads using their private key and receiving postbacks from Apple at their SKAdNetwork endpoint.</p>
        </li>
        <li>
          <p className="text--bold"> The Publisher</p>
          <p>The Publisher will include The Ad Network's SKAN ID in their info.plist file, request signed ads from The Ad Network, and call the appropriate
              functions for SKAdNetwork (LoadProduct(), StartImpression()).</p>
        </li>
        <li>
          <p className="text--bold">The Advertiser</p>
          <p> The Advertiser is responsible for calling the appropriate SKAdNetwork functions when a user first launches their app, and after each
              event that the advertiser wishes to track within their app.</p>
        </li>
      </ul>
      <p>See our integration guides for more information on this process.</p>
    </Fragment>
  },
  {
    question: "I heard about delayed conversion tracking. How delayed are we talking here?",
    answer: <Fragment>
      <p>Conversion tracking will be delayed by a minimum of 24 hours. Once an install event happens on the advertisers app, a 24 hour timer will begin. If another event happens within this time frame, the timer will reset for another 24 hours. Once the timer expires without an event, the install and any events will be packaged together and sent within 0-24 hours after the timer expires.</p>
      <p>For more information see: <a href="https://developer.apple.com/documentation/storekit/skadnetwork/3566697-updateconversionvalue">https://developer.apple.com/documentation/storekit/skadnetwork/3566697-updateconversionvalue</a></p>
    </Fragment>
  },
  {
    question: "A large part of our users come from working with affiliates and affiliate networks. How will this work now?",
    answer: <p>SKAdNetwork brings with it changes that negatively affect an affiliate's ability to promote an app on IOS devices. Curate is uniquely positioned as both an Ad Network and a DSP, this has given us greater insight into how these changes will affect our partners. Contact us and we can work with you to go through how SKAdNetwork will affect you, and what can be done to prepare yourself for the upcoming changes.
  </p>
  },
  {
    question: "How will creative reporting work? I heard that will be limited creative information passed back now?",
    answer: <p>No creative information will be available on postbacks unless the campaign id (1-100) is used to determine the creative shown. There are currently rumours that Apple is looking to add a new field in the postback for creative tracking, and we are ready to implement this change as soon as it is done.
  </p>
  },
  {
    question: "How can I optimize my spend now with so much fewer data coming back?",
    answer: <p>Optimization will be harder in a SKAdNetwork world, but parallel tracking can help alleviate some of this.</p>
  },
  {
    question: "How will view-through attribution work?",
    answer: <Fragment>
      <p>In SKAdNetwork version 2.2, Apple has added view-through attribution. A publisher can now call a new function called StartImpression() when an ad is displayed. This function requires a signed ad similar to the LoadProduct function, with a key difference being the new "fidelity" field.</p>
      <p>This new field will determine whether the postback is a view through or click through ad presentation.</p>
      <p>For more information on view through attribution see Apples docs here:</p>
      <a href="
https://developer.apple.com/documentation/storekit/skadnetwork/signing_and_providing_ads">https://developer.apple.com/documentation/storekit/skadnetwork/signing_and_providing_ads</a>
    </Fragment>
  },
  {
    question: "Who determines the attribution windows (VTA/CTA)?",
    answer: <Fragment><p>Currently Apple controls the view-through attribution window and there is no way to adjust these windows.</p>
      <p>The windows can been seen here: <a href="https://developer.apple.com/documentation/storekit/skadnetwork/receiving_ad_attributions">https://developer.apple.com/documentation/storekit/skadnetwork/receiving_ad_attributions</a></p></Fragment>
  },
  {
    question: "How will click tracking work?",
    answer: <Fragment><p>With SKAdNetwork, you will no longer have CTR data as Apple now handles the process of sending a user through to the store when they click on an ad. In order to measure CTR data, we recommend using parallel tracking to continue receiving this information.</p>
      <p>Parallel tracking is the process of simulataneously sending a S2S click from the publishing app when the app calls LoadProduct().</p></Fragment>
  },
  {
    question: "I heard events are going to be delayed. How delayed?",
    answer: <p>Events are delayed the same way that installs are. Once an install or event happens on the Advertisers app, a timer will begin for 24 hours, any subsequent events fired during that time, will be batched up with the first event, and sent together once the timer runs out and no new event has been fired.
  </p>
  },
  {
    question: "What happens if an advertiser sends an event after a SKAdNetwork postback has already been fired?",
    answer: <p>Events that are sent after a SKAdNetwork postback will not be attributable against the original source of the install.</p>
  },
  {
    question: "What are the best practices for passing events back, knowing that there is up to a 24hr delay for each event?",
    answer: <p>It is best to keep events as close as possible when a user first installs your app. Any event fired after the initial postback is sent will no longer be attributable against the original installs source.</p>
  }
];

class SKAdNetwork extends Component {
  constructor() {
    super();
    this.state = {
      formFirstName: '',
      formLastName: '',
      formEmail: '',
      formCompany: '',
      formJobTitle: '',
      formSkadId: 'yes',
      formSendUpdate: false,
      formConsent: false,
      emailSent: false,
    };
  }

  componentDidMount() {
    const checkboxes = document.querySelectorAll('.skad-signup-form__checkbox');
    checkboxes.forEach(checkbox => checkbox.addEventListener('keydown', function (event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        checkbox.click();
      }
    }))
    document.getElementById('signup-form-consent').required = 'true';

    if (doesUserAllowAnimation()) {
      animateElements(document.querySelectorAll('.animate__fade-in'), 'fadeIn');
    } else {
      removeAnimation(document.querySelectorAll('.animate__fade-in'), 'animate__fade-in');
    }
  }

  handleConsent = (e) => {
    (this.state.formConsent) ? this.setState({ formConsent: false }) : this.setState({ formConsent: true });
  };

  handleUpdateConsent = e => {
    this.state.formSendUpdate ? this.setState({ formSendUpdate: false }) : this.setState({ formSendUpdate: true });
  }

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const {
      formFirstName,
      formLastName,
      formEmail,
      formCompany,
      formJobTitle,
      formSkadId,
      formSendUpdate,
      formConsent
    } = this.state;

    axios.post('https://formspree.io/f/mrgodyrj', {
      formFirstName,
      formLastName,
      formEmail,
      formCompany,
      formJobTitle,
      formSkadId,
      formSendUpdate,
      formConsent
    }).then((resp) => {
      this.setState({ emailSent: true });
    });
  };

  render() {
    return (
      <div id="skad-page">
        <CookiesBanner />
        <ScrollToTopOnMount />

        <section aria-label="SKAdNetwork" className="skad-hero">
          <h1 aria-hidden="false" tabIndex="-1" className="animate__fade-in">Can you still get attribution with SKAdNetwork? <span className="font--bold">Yes! Curate can show you how.</span></h1>
          <div className="divider__horizontal skad-hero__divider"></div>
          <p aria-hidden="false" tabIndex="-1" className="animate__fade-in"> With iOS 14.5 now in Beta, <strong>SKAdNetwork</strong> is expected to be released in the early Spring. Currently, SKAdNetwork 2.2 is available, and we expect more iterations of this release to come out, with further functionality, before iOS 14.5 is released. As a way to better understand our industry's challenges, we are offering for a limited time free <strong>SKAdNetwork</strong> verification and testing through our proprietary <strong>DSP SWARM</strong>. </p>
        </section>

        <section className='skad-body'>
          <div className='skad-body__about animate__fade-in'>
            <ul className="skad-body__about__list">
              <h3>SKAdNetwork industry links</h3>
              <li><a href='https://developer.apple.com/news/?id=wajvzt18'>Take advantage of new advertising attribution technologies</a></li>
              <li><a href='https://webkit.org/blog/11529/introducing-private-click-measurement-pcm/'>Introducing Private Click Measurement, PCM</a></li>
              <li><a href='https://developer.apple.com/documentation/storekit/skadnetwork'>Apple's SKAdNetwork Documentation</a></li>

              <h2>Appsflyer</h2>
              <li><a href='https://support.appsflyer.com/hc/en-us/articles/360011420698-SKAdNetwork-Solution-guide'>SKAdNetwork Solution guide</a></li>

              <h2>Adjust</h2>
              <li><a href='https://help.adjust.com/en/article/skadnetwork-settings'>SKAdNetwork Settings Article</a></li>

              <h2>Singular</h2>
              <li><a href='https://support.singular.net/hc/en-us/articles/360047448611-Introduction-to-Singular-s-SKAdNetwork-Solution'>Introduction to Singular's SKAdNetwork Solution</a></li>

              <h2>Branch</h2>
              <li><a href='https://blog.branch.io/skadnetwork-view-through-attribution-and-pcm-what-they-mean-for-mobile-marketers/'>SKAdNetwork 2.2 View-Through Attribution and PCM: What They Mean for Mobile Marketers</a></li>

              <h2>Kochava</h2>
              <li><a href='https://www.kochava.com/skadnetwork-support-in-a-box/'>SKAdNetwork Support-in-a-Box</a></li>

              <h2>Everflow</h2>
              <li><a href='https://www.everflow.io/post/ios-14-what-is-skadnetwork-and-how-to-prepare'>iOS 14: What Is SKAdNetwork And How To Prepare</a></li>
            </ul>
          </div>
          <div className="skad-body__form">
            <div aria-label="SKAd Signup form." className={this.state.emailSent ? 'signup-form signup-form--hide' : 'signup-form signup-form--show'}>
              <h2 className="skad-body__form__title animate__fade-in">For your free <strong>SKAdNetwork</strong> verification test, sign-up here</h2>

              <form onSubmit={this.onSubmit} className="animate__fade-in">
                <TextInput
                  aria-label="First name contact form field. Used for identification in correspondence."
                  aria-required="true"
                  id="signup-form-first-name"
                  label="FIRST NAME"
                  name="formFirstName"
                  noLayout={true}
                  onChange={this.handleInput}
                  required={true}
                  tabIndex="0"
                  validate={false}
                />
                <TextInput
                  aria-label="Last name contact form field. Used for identification in correspondence."
                  aria-required="true"
                  id="signup-form-last-name"
                  label="LAST NAME"
                  name="formLastName"
                  noLayout={true}
                  onChange={this.handleInput}
                  required={true}
                  tabIndex="0"
                  validate={false}
                />
                <TextInput
                  aria-label="Email contact form field. Used for correspondence type."
                  aria-required="true"
                  email={true}
                  id="signup-form-email"
                  label="EMAIL"
                  name="formEmail"
                  onBlur={this.handleValidation}
                  noLayout={true}
                  onChange={this.handleInput}
                  required={true}
                  tabIndex="0"
                  validate
                />
                <TextInput
                  aria-label="Company contact form field. Used for identification in correspondence."
                  aria-required="true"
                  id="signup-form-company"
                  label="COMPANY"
                  name="formCompany"
                  noLayout={true}
                  onChange={this.handleInput}
                  required={true}
                  tabIndex="0"
                  validate={false}
                />
                <TextInput
                  aria-label="Job title contact form field. Used for identification in correspondence."
                  aria-required="true"
                  id="signup-form-job"
                  label="JOB TITLE"
                  name="formJobTitle"
                  noLayout={true}
                  onChange={this.handleInput}
                  required={true}
                  tabIndex="0"
                  validate={false}
                />
                <div className="signup-form__radio">
                  <label>I have a SKAdNetwork ID</label>
                  <div className="signup-form__radio__group">
                    <RadioGroup
                      aria-label="Radio button form field. I verify that I have a SKAdNetwork ID"
                      aria-required="true"
                      className="sigup-form__radio"
                      name="formSkadId"
                      id="signup-form-skad-id"
                      options={[{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]}
                      value={this.state.formSkadId}
                      onChange={this.handleInput}
                      required={true}
                      tabIndex="0"
                      withGap
                    />
                  </div>
                </div>

                <div className="signup-form__consent">
                  <Checkbox
                    aria-label="Checkbox form field. I consent to receive updates on SKAdNetwork. Used to confirm consent that Curate will send SKAdNetwork related news to your contact."
                    aria-required="true"
                    className="signup-form__checkbox"
                    filledIn
                    id="skad-update-consent"
                    label="I consent to receive updates on SKAdNetwork"
                    onChange={this.handleUpdateConsent}
                    tabIndex="0"
                    value="I consent"
                  />
                  <Checkbox
                    aria-label="Checkbox form field. I consent to my submitted data being collected and stored. Used to confirm consent that Curate will receive your contact form data."
                    aria-required="true"
                    className="signup-form__checkbox"
                    filledIn
                    id="signup-form-consent"
                    label="I consent to my submitted data being collected and stored."
                    onChange={this.handleConsent}
                    required={true}
                    tabIndex="0"
                    value="I consent"
                  />
                </div>

                <div className="signup-form__submit" tabIndex="0">
                  <input aria-label="Signup button. Selecting this will sign you up for your free SKAdNetwork verification test from Curate Mobile and will also update the context of this page to replace the form element with a success message" className="signup-form__submit__button" id="signup-form-submit" type="submit" value="SIGN UP" />
                  <p className="signup-form__submit__legal" role="contentinfo">Our website collects visitor data such as location and site navigation to build the best experience for users on our website, if you would like to opt out please visit our <Link aria-label="Privacy Policy. Internal link to privacy policy page." onKeyDown={handleLinkKeyDown} role="listitem" to="/PrivacyPolicy">Privacy Policy</Link>.</p>
                </div>
              </form>
            </div>

            <div aria-label="Contact form confirmation of submission." className={this.state.emailSent ? 'signup-form__confirmation signup-form--show' : 'signup-form__confirmation signup-form--hide'}>
              <h3>Thank you for requesting a free <strong>SKAdNetwork</strong> verification test.</h3>
              <p id="form-confirmation-message" tabIndex="0">We will be in contact with you shortly to learn more about your SKAdNetwork requirements!<br /><br /> In the meantime, feel free to read through our list of SKAdNetwork resources and contact us if you have any questions.</p>
            </div>
          </div>
        </section>

        <section className="skad-integration">
          <h2><span className="text--bold">SKAdNetwork</span> Integration Guides</h2>
          <div className="divider__horizontal skad-integration__divider"></div>
          <p className="skad-integration__subtitle">Download our integration guides on implementing SKAdNetwork with Curate.</p>
          <div className="skad-integration__thumbnails">
            <div className="skad-integration__thumbnail">
              <a aria-label="Integrating SKAdNetwork tutorial all" href={allSkadNetworksPDF} download>
                <img alt="A clickable thumbnail of Curate Mobile's Integrating SKAdNetwork Tutorial Package" src={allSkadNetworks} />
              </a>
              <div className="skad-integration__thumbnail__overlay overlay--white">
                <Icon>system_update_alt</Icon>
              </div>
              <p className="text--bold">For All</p>
            </div>
            <div className="skad-integration__thumbnail">
              <a aria-label="Integrating SKAdNetwork tutorial for advertisers" href={skadAdvertisersPDF} download>
                <img alt="A clickable thumbnail of Curate Mobile's Integrating SKAdNetwork Tutorial for advertisers" src={skadAdvertisers} />
              </a>
              <div className="skad-integration__thumbnail__overlay">
                <Icon>system_update_alt</Icon>
              </div>
              <p className="text--bold">For Advertisers</p>
            </div>
            <div className="skad-integration__thumbnail">
              <a aria-label="Integrating SKAdNetwork tutorial for publishers" href={skadPublishersPDF} download>
                <img alt="A clickable thumbnail of Curate Mobile's Integrating SKAdNetwork Tutorial for publishers" src={skadPublishers} />
              </a>
              <p className="text--bold">For Publishers</p>
              <div className="skad-integration__thumbnail__overlay">
                <Icon>system_update_alt</Icon>
              </div>
            </div>
            <div className="skad-integration__thumbnail">
              <a aria-label="Integrating SKAdNetwork tutorial for ad networks" href={skadAdNetworksPDF} download>
                <img alt="A clickable thumbnail of Curate Mobile's Integrating SKAdNetwork Tutorial for ad networks" src={skadAdNetworks} />
              </a>
              <p className="text--bold">For Ad Networks</p>
              <div className="skad-integration__thumbnail__overlay">
                <Icon>system_update_alt</Icon>
              </div>
            </div>
          </div>
        </section>
        <section className='skad-faq'>
          <h1 aria-hidden="false" tabIndex="-1" className="animate__fade-in font--bold">Frequently Asked Questions</h1>
          <div className="divider__horizontal skad-faq__divider"></div>
          <Collapsible accordion={false} className="skad-faq__accordion">
            {faqQuestionsAndAnswers.map((faq, index) => (
              <CollapsibleItem
                className="skad-faq__accordion__item font--bold"
                expanded={false}
                header={faq.question}
                icon={<Icon>expand_more</Icon>}
                key={index}
                node="div"
              >
                {faq.answer}
              </CollapsibleItem>
            ))}
          </Collapsible>
        </section>
        <section className="swarm-and-skad">
          <h2><span className="text--bold">Swarm</span> and <span className="text--bold">SKAdNetwork</span></h2>
          <div className="divider__horizontal swarm-and-skad__divider"></div>
          <p className="swarm-and-skad__content">
              Swarm's unique position in the SKAdNetwork Ecosystem. <a aria-label="Download Swarms unique position in the SKAdNetwork Ecosystem" href={swarmsUniquePositionPDF}>Download</a>
            </p>
          <div className="skad-body__about__diagram">
            <Modal
              id="Modal-0"
              open={false}
              options={{
                dismissible: true,
                endingTop: '0%',
                inDuration: 250,
                onCloseEnd: null,
                onCloseStart: null,
                onOpenEnd: null,
                onOpenStart: null,
                opacity: 0.5,
                outDuration: 250,
                preventScrolling: true
              }}
              trigger={<img alt="Diagram of SKAdNetwork position within external DSP and SWARM" src={skadPosition} />}
            >
              <img alt="Diagram of SKAdNetwork position within external DSP and SWARM" src={skadPosition} />
              <Button flat modal="close" node="button" waves="green">Close</Button>
            </Modal>
            <div className="diagram__overlay">
              <Icon>fullscreen</Icon>
            </div>
          </div>
        </section>

        <Helmet title='SKAdNetwork' />
      </div>
    )
  }
}

export default SKAdNetwork;
