// Dependencies
import 'matchmedia-polyfill';
import 'matchmedia-polyfill/matchMedia.addListener';
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
// eslint-disable-next-line
import * as $ from 'jquery';

// Style
import 'normalize.css';
import './Styles/vendor/animate.css';
import './Styles/Shared.css';

// Components
import NavBar from './Components/NavBar/NavBar';
import Footer from './Components/Footer';

// Views
import LandingPage from './Views/Landing';
import SolutionsPage from './Views/Solutions';
import PartnerManagement from './Views/PartnerManagement';
import ProductsPage from './Views/Products';
import CompanyPage from './Views/Company';
import ContactPage from './Views/Contact';
import SKAdNetworkPage from './Views/SKAdNetwork';
import SKAdService from './Views/SKAdService';
import FraudBlockPage from './Views/FraudBlock';
import PrivacyPolicy from './Views/PrivacyPolicy';
import NoMatch from './Views/NoMatch';
import SiteMap from './Views/SiteMap';

let pageTitle = 'Curate Mobile';
const pageTitleEnd = '| Curate';
const routes = [
	{
		title: 'Digital and mobile advertising experts',
		path: '/',
		component: LandingPage,
		exact: true
	},
	{
		title: 'Advertising solutions built for a mobile world',
		path: '/Solutions',
		component: SolutionsPage,
		exact: true
	},
	{
		title: 'Partner management platform',
		path: '/Partner-management-platform',
		component: PartnerManagement,
		exact: true
	},
	{
		title: 'Products that drive consumer connections',
		path: '/TechStack',
		component: ProductsPage,
		exact: true
	},
	{
		title: 'We put people first, ours and yours',
		path: '/Company',
		component: CompanyPage,
		exact: true
	},
	{
		title: 'SKAdNetwork',
		path: '/SKAdNetwork',
		component: SKAdNetworkPage,
		exact: true
	},
	{
		title: 'SKAdNetwork as a Service',
		path: '/SKAdNetwork-as-a-Service',
		component: SKAdService,
		exact: true
	},
	{
		title: 'Fraud Blocking',
		path: '/FraudBlocking',
		component: FraudBlockPage,
		exact: true
	},
	{
		title: 'Contact Us',
		path: '/Contact',
		component: ContactPage,
		exact: true
	},
	{
		title: 'Privacy Policy',
		path: '/PrivacyPolicy',
		component: PrivacyPolicy,
		exact: true
	},
	{
		title: 'Site Map',
		path: '/SiteMap',
		component: SiteMap,
		exact: true
	}
];

class App extends Component {
	render() {
		return (
			<Router>
				<Route render={(props) => {
					routes.forEach((route) => {
						if (route.path === props.location.pathname) {
							pageTitle = route.title;
						}
					});

					return (
						<div className="page container-fluid">
							<Helmet titleTemplate={`${pageTitle} ${pageTitleEnd}`} />

							<NavBar />
							<Switch>
								<Route exact path="/Products" render={() => (<Redirect to={"/TechStack" + (props.location.hash.length ? props.location.hash : "")} />)} />
								{routes.map((route, i) => (
									<Route key={i} exact={route.exact} path={route.path} title={route.title} component={() => <route.component />} />
								))}
								<Route component={NoMatch} />
							</Switch>
							<Footer />
						</div>
					)
				}} />
			</Router>
		);
	}
}

export default App;
