// Dependencies
import React, { Component } from 'react';
import Helmet from 'react-helmet';
// Local files
import { doesUserAllowAnimation, setupAnimationObserver, animateElement, animateElements, removeAnimation } from '../helpers';

// Style
import '../Styles/Products.css';

// Images
import logoTag from '../Images/products/logo-dsp-tag.png';

import iconLocation from '../Images/products/icon-dsp-location.png';
import iconMachine from '../Images/products/icon-dsp-machine.png';
import iconBidder from '../Images/products/icon-dsp-bidder.png';
import iconAudience from '../Images/products/icon-dsp-audience.png';
import iconTarget from '../Images/products/icon-dsp-target.png';
import iconAttribution from '../Images/products/icon-dsp-attribution.png';
import iconReporting from '../Images/products/icon-dsp-reporting.png';

import graphicDesktop from '../Images/products/animation-dsp-desktop-frame.gif';

import iconDownload from '../Images/products/icon-console-download.png';
import iconTargetConsole from '../Images/products/icon-console-target.png';
import iconGearConsole from '../Images/products/icon-console-gear.png';
import imageConsole from '../Images/products/image-console-screenshot.png';
import KismetLogo from '../Images/components/kismet-logo-large.png';

// Components
import CookiesBanner from "../Components/CookiesBanner";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";
import CarouselProductsDSP from "../Components/CarouselProductsDSP";

class Solutions extends Component {
	componentDidMount() {
		const section = window.location.hash;
		if (section === '#GrowthPlatform') {
			const yPos = document.querySelector('.products-hero').getBoundingClientRect().height;
			window.setTimeout(() => {
				document.querySelector('.products-dsp__top h2').focus();
				window.scrollTo({
					top: yPos,
					left: 0,
					behavior: 'smooth'
				});
			}, 250);
		} else if (section === '#MobileDSP') {
			let yPos = document.querySelector('.products-hero').getBoundingClientRect().height;
			yPos += document.querySelector('.products-console').getBoundingClientRect().height;
			window.setTimeout(() => {
				document.querySelector('.products-dsp__title h2').focus();
				window.scrollTo({
					top: yPos,
					left: 0,
					behavior: 'smooth'
				});
			}, 250);
		}

		if (doesUserAllowAnimation()) {
			setupAnimationObserver(document.querySelectorAll('section'), 'animate__fade-in', 'fadeIn', 0.8);

			setupAnimationObserver(document.querySelectorAll('.products-dsp__top'), 'animate__fade-in', 'fadeIn', 0.8);
			setupAnimationObserver(document.querySelectorAll('.products-dsp__icons'), 'animate__fade-in', 'fadeIn', 0.8);
			setupAnimationObserver(document.querySelectorAll('.products-dsp__features__left'), 'animate__fade-in', 'fadeIn', 0.8);

			setupAnimationObserver(document.querySelectorAll('.products-console__top'), 'animate__fade-in', 'fadeIn', 0.8);
			setupAnimationObserver(document.querySelectorAll('.products-console__icons'), 'animate__fade-in', 'fadeIn', 0.8);
			setupAnimationObserver(document.querySelectorAll('.products-console__features__left'), 'animate__fade-in', 'fadeIn', 0.8);

			// animation for products-dsp__icons icons
			(function () {
				const animationOptions = {
					root: null,
					rootMargin: '0px',
					threshold: 0.9
				};

				let observerFadeInUp = new IntersectionObserver((element) => {
					const fadeDelay = 500;
					if (element[0].intersectionRatio > 0.8) {
						if (window.innerWidth <= 599) {
							animateElement(element[0].target.querySelector('.animate__fade-in-up-1'), 'fadeInUp');
							animateElement(element[0].target.querySelector('.animate__fade-in-up-2'), 'fadeInUp');
							window.setTimeout(() => {
								animateElement(element[0].target.querySelector('.animate__fade-in-up-3'), 'fadeInUp');
								animateElement(element[0].target.querySelector('.animate__fade-in-up-4'), 'fadeInUp');
							}, fadeDelay);
							window.setTimeout(() => {
								animateElement(element[0].target.querySelector('.animate__fade-in-up-5'), 'fadeInUp');
								animateElement(element[0].target.querySelector('.animate__fade-in-up-6'), 'fadeInUp');
							}, fadeDelay * 2);
							window.setTimeout(() => {
								animateElement(element[0].target.querySelector('.animate__fade-in-up-7'), 'fadeInUp');
							}, fadeDelay * 3);
						} else if (window.innerWidth > 599 && window.innerWidth <= 799) {
							animateElement(element[0].target.querySelector('.animate__fade-in-up-1'), 'fadeInUp');
							animateElement(element[0].target.querySelector('.animate__fade-in-up-2'), 'fadeInUp');
							animateElement(element[0].target.querySelector('.animate__fade-in-up-3'), 'fadeInUp');
							window.setTimeout(() => {
								animateElement(element[0].target.querySelector('.animate__fade-in-up-4'), 'fadeInUp');
								animateElement(element[0].target.querySelector('.animate__fade-in-up-5'), 'fadeInUp');
								animateElement(element[0].target.querySelector('.animate__fade-in-up-6'), 'fadeInUp');
							}, fadeDelay);
							window.setTimeout(() => {
								animateElement(element[0].target.querySelector('.animate__fade-in-up-7'), 'fadeInUp');
							}, fadeDelay * 2);
						} else {
							animateElement(element[0].target.querySelector('.animate__fade-in-up-1'), 'fadeInUp');
							animateElement(element[0].target.querySelector('.animate__fade-in-up-2'), 'fadeInUp');
							animateElement(element[0].target.querySelector('.animate__fade-in-up-3'), 'fadeInUp');
							animateElement(element[0].target.querySelector('.animate__fade-in-up-4'), 'fadeInUp');
							window.setTimeout(() => {
								animateElement(element[0].target.querySelector('.animate__fade-in-up-5'), 'fadeInUp');
								animateElement(element[0].target.querySelector('.animate__fade-in-up-6'), 'fadeInUp');
								animateElement(element[0].target.querySelector('.animate__fade-in-up-7'), 'fadeInUp');
							}, fadeDelay);
						}
					}
				}, animationOptions);

				observerFadeInUp.observe(document.querySelector('.products-dsp__icons'));
			})();

			// animation for products-dsp__features__left content
			(function () {
				const animationOptions = {
					root: null,
					rootMargin: '0px',
					threshold: 0.5
				};

				let observerFadeInUp = new IntersectionObserver((element) => {
					if (element[0].intersectionRatio > 0.5) {
						if (window.innerWidth >= 881) {
							animateElements(element[0].target.querySelectorAll('.animate__fade-in-left'), 'fadeInLeft');
						}
					}
				}, animationOptions);

				observerFadeInUp.observe(document.querySelector('.products-dsp__features__left'));
			})();

			// animation for products-dsp__features__right content
			(function () {
				const animationOptions = {
					root: null,
					rootMargin: '0px',
					threshold: 0.5
				};

				let observerFadeInUp = new IntersectionObserver((element) => {
					if (element[0].intersectionRatio > 0.3) {
						if (window.innerWidth < 881) {
							animateElement(element[0].target.querySelector('.animate__fade-in'), 'fadeIn');
						}
						if (window.innerWidth >= 881) {
							animateElement(element[0].target.querySelector('.animate__fade-in-right'), 'fadeInRight');
						}
					}
				}, animationOptions);

				observerFadeInUp.observe(document.querySelector('.products-dsp__features__right'));
			})();

			// animation for products-console__features__left content
			(function () {
				const animationOptions = {
					root: null,
					rootMargin: '0px',
					threshold: 0.5
				};

				let observerFadeInUp = new IntersectionObserver((element) => {
					if (element[0].intersectionRatio > 0.5) {
						if (window.innerWidth >= 881) {
							animateElements(element[0].target.querySelectorAll('.animate__fade-in-left'), 'fadeInLeft');
						}
					}
				}, animationOptions);

				observerFadeInUp.observe(document.querySelector('.products-console__features__left'));
			})();

			// animation for products-console__features__right content
			(function () {
				const animationOptions = {
					root: null,
					rootMargin: '0px',
					threshold: 0.5
				};

				let observerFadeInUp = new IntersectionObserver((element) => {
					if (element[0].intersectionRatio > 0.5) {
						if (window.innerWidth < 881) {
							animateElement(element[0].target.querySelector('.animate__fade-in'), 'fadeIn');
						}
						if (window.innerWidth >= 881) {
							animateElement(element[0].target.querySelector('.animate__fade-in-right'), 'fadeInRight');
						}
					}
				}, animationOptions);

				observerFadeInUp.observe(document.querySelector('.products-console__features__right'));
			})();
		} else {
			removeAnimation(document.querySelectorAll('.animate__fade-in'), 'animate__fade-in');
			for (let i = 1; i <= 7; i++) {
				removeAnimation(document.querySelectorAll(`.animate__fade-in-up-${i}`), `animate__fade-in-up-${i}`);
			}
			removeAnimation(document.querySelectorAll('.animate__fade-in-left'), 'animate__fade-in-left');
			removeAnimation(document.querySelectorAll('.animate__fade-in-right'), 'animate__fade-in-right');
		}
	}

	render() {
		return (
			<div id="solutions-page">
				<CookiesBanner />
				<ScrollToTopOnMount />

				<section aria-label="Background image of a happy family embracing." className="products-hero">
					<h1 aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Driving Consumer <span className="font--bold">Connections</span>.</h1>
					<p aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Curate offers a full end-to-end mobile solution driving everything from brand awareness with bespoke creative to user-installs, leveraging data to calculate every&nbsp;touchpoint.</p>
				</section>

				<section aria-label="Curate's performance supply optimization platform, Console." className="products-console">
					<div className="products-dsp__top">
						<h2 aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1"><span className="font--bold">Growth Marketing</span><br /> Platform & Bidder.</h2>
						<p aria-hidden="false" className="animate__fade-in products-console__product-description" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">The cost of acquiring a mobile consumer is rapidly outpacing the lifetime value of the consumer. Fraud and lack of transparency are contributing factors, as such, Console puts control and safety back in the hands of the&nbsp;growth-marketer.</p>
					</div>
					<div className="products-console__icons">
						<div aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">
							<img alt='Icon representing a download.' src={iconDownload} />
							<p>Drive installs with<br />consumer acquisition</p>
						</div>
						<div aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">
							<img alt='Icon representing a download.' src={iconGearConsole} />
							<p>Proprietary in-house bidder<br />& media buying</p>
						</div>
						<div aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">
							<img alt='Icon of an arrow hitting a target.' src={iconTargetConsole} />
							<p>Retarget & re-engage<br />the most valuable users</p>
						</div>
					</div>
					<div className="products-console__features">
						<div className="products-console__features__left">
							<h3 aria-hidden="false" className="animate__fade-in animate__fade-in-left" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Key Features:</h3>
							<p aria-hidden="false" className="animate__fade-in animate__fade-in-left products-console__features__left__subtitle" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1"><span className="font--bold">Proprietary Bidder:<br /></span> Log-level control of your campaign and optimization&nbsp;goals.</p>
							<p aria-hidden="false" className="animate__fade-in animate__fade-in-left products-console__features__left__subtitle" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1"><span className="font--bold">Partnership Management Platform:<br /></span> Curate’s unique tool that helps promote key pillars of transparency and customization.  Advertisers are granted a secure log-in to monitor campaigns in real-time and possess full discretion on attribution settings.</p>
							<p aria-hidden="false" className="animate__fade-in animate__fade-in-left products-console__features__left__subtitle" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1"><span className="font--bold">Unique Algorithms:<br /></span> Reach CPA/CPI and ROAS goals using our optimization driven proprietary RTB&nbsp;algorithms.</p>
							<p aria-hidden="false" className="animate__fade-in animate__fade-in-left products-console__features__left__subtitle" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1"><span className="font--bold">Retargeting & Re-engagement:<br /></span> With a powerful machine-learning based approach. Curate can target and re-engage users to drive more in-app actions &&nbsp;conversions.</p>
							<p aria-hidden="false" className="animate__fade-in animate__fade-in-left products-console__features__left__subtitle" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1"><span className="font--bold">In-house Media Buying:<br /></span> Dedicated media buying managed or self-service solutions. We provide app-developers and marketers with tools to manage all user acquisition and retargeting&nbsp;campaigns.</p>
							<p aria-hidden="false" className="animate__fade-in animate__fade-in-left products-console__features__left__subtitle" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1"><span className="font--bold">Curate Fraud Tools:<br /></span> Install Analyzer, Click Analyzer and Curate Alert tools for fraud prevention, detection and&nbsp;monitoring. These anti-fraud features provide an added layer of protection to the standard MMPs in order to deliver a best-in-class solution.</p>
						</div>
						<div className="products-console__features__right">
							<img alt="Screenshot of Curate's console platform featuring example publisher names, impression counts, conversion rates, and other information." aria-hidden="false" className="animate__fade-in animate__fade-in-right" onFocus={((event) => animateElement(event.target, 'fadeIn'))} src={imageConsole} tabIndex="-1" />
						</div>
					</div>
				</section>

				<section aria-label="Curate's mobile-first DSP, Swarm." className="products-dsp">
					<div className="products-dsp__top">
						<img alt="Logo of TAG: Certified against fraud" className="products-dsp__tag-image" src={logoTag} />
						<div className="products-dsp__title">
							<h2 aria-hidden="false" className="animate__fade-in" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Our <span className="font--bold">Mobile-First</span> DSP</h2>
							<img alt="Logo of TAG: Certified against fraud" src={logoTag} />
						</div>
						<p aria-hidden="false" className="animate__fade-in products-dsp__product-description" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">It’s Kismet! Check out our Mobile-first proprietary programmatic solution for advertisers and agencies <a href="https://kismet.ai">here</a>.</p>
					</div>
					<div className="products-dsp__icons">
						<div aria-hidden="false" className="animate__fade-in-up-1" onFocus={((event) => animateElement(event.target, 'fadeInUp'))} tabIndex="-1">
							<img alt='Icon of a map location indicator.' src={iconLocation} />
							<p>Location targeting<br />& insights</p>
						</div>
						<div aria-hidden="false" className="animate__fade-in-up-2" onFocus={((event) => animateElement(event.target, 'fadeInUp'))} tabIndex="-1">
							<img alt='Icon of a gear.' src={iconMachine} />
							<p>Machine learning</p>
						</div>
						<div aria-hidden="false" className="animate__fade-in-up-3" onFocus={((event) => animateElement(event.target, 'fadeInUp'))} tabIndex="-1">
							<img alt='Icon of a mobile phone displaying an advertisement.' src={iconBidder} />
							<p>Proprietary bidder</p>
						</div>
						<div aria-hidden="false" className="animate__fade-in-up-4" onFocus={((event) => animateElement(event.target, 'fadeInUp'))} tabIndex="-1">
							<img alt='Icon of a mouse cursor.' src={iconAudience} />
							<p>Audience data &<br />optimization</p>
						</div>
						<div aria-hidden="false" className="animate__fade-in-up-5" onFocus={((event) => animateElement(event.target, 'fadeInUp'))} tabIndex="-1">
							<img alt='Icon of an arrow hitting a target.' src={iconTarget} />
							<p>Targeting & scale</p>
						</div>
						<div aria-hidden="false" className="animate__fade-in-up-6" onFocus={((event) => animateElement(event.target, 'fadeInUp'))} tabIndex="-1">
							<img alt='Icon of a hand holding a credit card.' src={iconAttribution} />
							<p>Measurement &<br />attribution</p>
						</div>
						<div aria-hidden="false" className="animate__fade-in-up-7" onFocus={((event) => animateElement(event.target, 'fadeInUp'))} tabIndex="-1">
							<img alt='Icon of a bar graph.' src={iconReporting} />
							<p>Custom reporting</p>
						</div>
					</div>
					<CarouselProductsDSP />
					<div className="products-dsp__features">
						<div className="products-dsp__features__left">
							<h3 aria-hidden="false" className="animate__fade-in animate__fade-in-left" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1">Key Features:</h3>
							<p aria-hidden="false" className="animate__fade-in animate__fade-in-left products-dsp__features__left__subtitle" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1"><span className="font--bold">TraceTM: Hyper-Local Targeting Tool:<br /></span> Deliver messaging within one - or many - locations with our precise polygon building tool without sacrificing&nbsp;scale.</p>
							<p aria-hidden="false" className="animate__fade-in animate__fade-in-left products-dsp__features__left__subtitle" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1"><span className="font--bold">Verified Location Targeting:<br /></span> Machine learning-based insights that understand the link between consumer personas and where they are in the&nbsp;world.</p>
							<p aria-hidden="false" className="animate__fade-in animate__fade-in-left products-dsp__features__left__subtitle" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1"><span className="font--bold">Contextual & Sentiment Targeting:<br /></span> Effective targeting capabilities built for a privacy-forward&nbsp;world.</p>
							<p aria-hidden="false" className="animate__fade-in animate__fade-in-left products-dsp__features__left__subtitle" onFocus={((event) => animateElement(event.target, 'fadeIn'))} tabIndex="-1"><span className="font--bold">Brand Safety & 3rd Party Verification:<br /></span> Our DSP has both pre and post-bid IAS integrations and is TAG&nbsp;certified.</p>
						</div>
						<div className="products-dsp__features__right">
							<img alt="Animated gif of Curate's Swarm DSP showcasing the feature of the drawing polygon shape top on a map for hyper local advertisement targeting." aria-hidden="false" className="animate__fade-in animate__fade-in-right" onFocus={((event) => animateElement(event.target, 'fadeIn'))} src={graphicDesktop} tabIndex="-1" />
						</div>
					</div>
				</section>

        <section aria-label="View Kismet" className="skadservice-vision">
          <img src={KismetLogo} alt="Kismet Logo" style={{ width: "35px", paddingBottom: "5px" }}/>
          <p aria-hidden="false" >
            Visit{" "}
            <a href="https://kismet.ai" target="_blank" rel="noopener noreferrer">
              Kismet
            </a>{" "}
            today to learn more!
          </p>
        </section>
				<Helmet title='Products' />
			</div>
		);
	}
}

export default Solutions;
